.light-background {
  --text-color: var(--text-dark-color);
  --text-grey-color: var(--text-grey-dark-color);
  --hyperlink-color: var(--hyperlink-dark-color);
  --hyperlink-hover-color: var(--hyperlink-dark-hover-color);
  --menu-item-color: var(--menu-item-dark-color);
  --menu-item-hover-color: var(--menu-item-dark-hover-color);
  --navbar-background-color: var(--navbar-background-dark-color);
  --mobile-nav-hamburger-color: var(--menu-item-dark-color);
  --menu-subitem-hover-color: var(--menu-item-dark-hover-color);
  --fill-1-color: var(--fill-dark-1-color);
  --fill-2-color: var(--fill-dark-2-color);
  --fill-3-color: var(--fill-dark-3-color);
  --background-1-color: var(--fill-light-1-color);
  --background-2-color: var(--fill-light-2-color);
  --background-3-color: var(--fill-light-3-color);
  --image-placeholder-color: var(--image-placeholder-dark-color);
  --icon-color: var(--icon-dark-color);
  --icon-background-color: var(--icon-background-dark-color);
  --icon-border-color: var(--icon-border-dark-color);
  --border-color: var(--fill-grey-1-color);
  --button-primary-color: var(--button-primary-dark-color);
  --button-primary-text-color: var(--button-primary-text-dark-color);
  --button-primary-hover-color: var(--button-primary-hover-dark-color);
  --button-primary-hover-text-color: var(--button-primary-hover-text-dark-color);
  --button-secondary-color: var(--button-secondary-dark-color);
  --button-secondary-text-color: var(--button-secondary-text-dark-color);
  --button-secondary-hover-color: var(--button-secondary-hover-dark-color);
  --button-secondary-hover-text-color: var(--button-secondary-hover-text-dark-color);
  --button-list-color: var(--button-list-dark-color);
  --button-list-text-color: var(--button-list-text-dark-color);
  --button-list-hover-color: var(--button-list-hover-dark-color);
  --button-list-hover-text-color: var(--button-list-hover-text-dark-color)
}

.dark-background {
  --text-color: var(--text-light-color);
  --text-grey-color: var(--text-grey-light-color);
  --hyperlink-color: var(--hyperlink-light-color);
  --hyperlink-hover-color: var(--hyperlink-light-hover-color);
  --menu-item-color: var(--menu-item-light-color);
  --menu-subitem-color: var(--menu-item-dark-color);
  --menu-item-hover-color: var(--menu-item-dark-hover-color);
  --navbar-background-color: var(--navbar-background-light-color);
  --mobile-nav-hamburger-color: var(--menu-item-light-color);
  --menu-subitem-hover-color: var(--menu-item-dark-hover-color);
  --fill-1-color: var(--fill-light-1-color);
  --fill-2-color: var(--fill-light-2-color);
  --fill-3-color: var(--fill-light-3-color);
  --background-1-color: var(--fill-dark-1-color);
  --background-2-color: var(--fill-dark-2-color);
  --background-3-color: var(--fill-dark-3-color);
  --border-color: var(--fill-grey-1-color);
  --image-placeholder-color: var(--image-placeholder-light-color);
  --icon-color: var(--icon-light-color);
  --icon-background-color: var(--icon-background-light-color);
  --icon-border-color: var(--icon-border-light-color);
  --button-primary-color: var(--button-primary-light-color);
  --button-primary-text-color: var(--button-primary-text-light-color);
  --button-primary-hover-color: var(--button-primary-hover-light-color);
  --button-primary-hover-text-color: var(--button-primary-hover-text-light-color);
  --button-secondary-color: var(--button-secondary-light-color);
  --button-secondary-text-color: var(--button-secondary-text-light-color);
  --button-secondary-hover-color: var(--button-secondary-hover-light-color);
  --button-secondary-hover-text-color: var(--button-secondary-hover-text-light-color);
  --button-list-color: var(--button-list-light-color);
  --button-list-text-color: var(--button-list-text-light-color);
  --button-list-hover-color: var(--button-list-hover-light-color);
  --button-list-hover-text-color: var(--button-list-hover-text-light-color)
}

*,*:before,*:after {
  box-sizing: border-box
}

html,body {
  height: 100%;
  /*overflow-x: hidden;*/
}

body {
  background: var(--site-background);
  color: var(--text-color);
  width: 100%;
  margin: 0;
  font-family: var(--body-font-family);
  font-weight: var(--body-font-weight);
  line-height: var(--body-text-line-height);
  font-size: var(--body-text-medium-font-size)
}

body.nav-open {
  position: fixed;
  overflow: hidden
}

html.parallax {
  overflow: hidden
}

.site-disabled-bar__info {
  margin: 8px 0;
  font-family: 'Source Sans pro',Sans-Serif
}

.text-input {
  font-family: var(--ui-font-family);
  font-weight: var(--ui-font-weight);
  line-height: var(--ui-line-height);
  font-size: var(--ui-medium-size);
  border: var(--input-border);
  padding: var(--input-padding);
  border-radius: var(--input-border-radius)
}

.text-input:focus {
  border-color: var(--input-focus-border-color);
  outline: none
}

iframe {
  border: 0
}

.social-icon--faithlife {
  color: #5fbc39
}

:root {
  --display-font-size: 48px;
  --display-line-height: 1.3;
  --display-margin-bottom: 8px;
  --heading-line-height: 1.3;
  --h1-font-size: 42px;
  --h2-font-size: 32px;
  --h3-font-size: 28px;
  --h4-font-size: 20px;
  --h5-font-size: 18px;
  --h6-font-size: 16px;
  --edit-item-width: 24px;
  --group-branding-font-size: 20px;
  --group-branding-line-height: 1.3;
  --fragment-title-font-size: 32px;
  --fragment-title-line-height: 1.3;
  --fragment-title-letter-spacing: unset;
  --fragment-content-background: transparent;
  --fragment-content-box-shadow: var(--item-box-shadow);
  --fragment-content-border: initial;
  --fragment-content-border-width: 0;
  --fragment-content-border-radius: var(--item-border-radius);
  --fragment-content-padding: 0;
  --fragment-content-text-align: center;
  --fragment-content-text-align-2col: center;
  --fragment-content-text-align-3col: center;
  --body-text-large-font-size: 18px;
  --body-text-medium-font-size: 16px;
  --body-text-small-font-size: 14px;
  --body-text-line-height: 1.3;
  --ui-large-size: 18px;
  --ui-medium-size: 16px;
  --ui-medium-small-size: 14px;
  --ui-small-size: 12px;
  --ui-line-height: 1;
  --line-clamp-max-height: 3.9em;
  --hero-sub-header-max-width: 600px;
  --hero-border-radius: var(--item-border-radius);
  --mobile-nav-height: 120px;
  --hero-buttons-margin: 0;
  --hero-min-height: 730px;
  --slideout-menu-height: 100vh;
  --slideout-menu-padding: 0;
  --menu-item-padding: 12px 16px;
  --menu-subitem-padding: 12px 16px;
  --site-background: initial;
  --edit-item-top: 10px;
  --edit-item-right: 10px;
  --item-border-radius: 0;
  --edit-item-left: 10px;
  --reorder-section-right: 10px;
  --button-display: inline-block;
  --button-box-shadow: 0 0 8px -2px #9b9b9b;
  --button-border: 1px solid;
  --button-border-radius: 3px;
  --button-padding: 12px 24px;
  --button-min-width: 120px;
  --button-transition: all .15s;
  --button-secondary-border: 2px solid;
  --button-secondary-background: transparent;
  --fragment-background: initial;
  --fragment-column-gutter: 32px;
  --multimedia-embed-background: black;
  --multimedia-aspect-ratio-percentage: 66.66666667%;
  --fragment-title-margin-bottom: 32px;
  --fragment-title-text-align: center;
  --fragment-title-text-transform: initial;
  --fragment-title-text-align-2col: center;
  --fragment-title-text-align-3col: center;
  --fragment-cta-align-items: center;
  --fragment-cta-justify-content: center;
  --fragment-cta-button-margin-top: 24px;
  --fragment-cta-title-margin: 16px 0 12px;
  --fragment-cta-box-shadow: unset;
  --fragment-view-all-margin-top: 32px;
  --fragment-view-all-text-align: center;
  --fragment-box-shadow: unset;
  --fragment-border: unset;
  --fragment-border-width: unset;
  --fragment-border-radius: var(--item-border-radius);
  --vertical-fragment-section-padding: 24px;
  --fragment-padding: 48px 0;
  --fragment-image-background-position: center center;
  --fragment-image-background-size: cover;
  --fragment-overflow: initial;
  --fragment-two-col-min-height: auto;
  --item-box-shadow: none;
  --item-button-width: auto;
  --item-icon-display: flex;
  --item-icon-border: 1px solid;
  --item-icon-border-radius: 50%;
  --item-icon-container-diameter: 44px;
  --item-icon-background-color: transparent;
  --list-item-justify-content: space-between;
  --list-item-flex-direction: column;
  --list-item-align-items: flex-start;
  --list-item-margin: 0;
  --top-list-item-top-padding: 0;
  --list-item-padding: 24px 0;
  --list-item-details-margin: 16px 0;
  --list-item-details-flex-direction: column;
  --list-item-details-align-items: flex-start;
  --list-item-border: #dadada solid;
  --list-item-border-width: 0 0 1px;
  --list-item-border-radius: var(--item-border-radius);
  --list-item-background: transparent;
  --list-item-container-width: 100%;
  --list-item-container-align-items: flex-start;
  --list-item-container-flex-direction: column;
  --list-item-subtitle-display: flex;
  --list-item-subtitle-margin: 8px 0 0;
  --button-list-display: block;
  --button-list-svg-margin: 0 0 0 2px;
  --button-list-border: 1px solid;
  --button-list-border-radius: 16px;
  --button-list-padding: 6px 16px;
  --button-list-margin: 12px 0 0;
  --cta-content-margin: 0 auto;
  --cta-content-height: 100%;
  --cta-content-max-width: 520px;
  --cta-content-text-align: unset;
  --cta-title-margin: 0 0 16px;
  --cta-body-margin: 0 0 24px;
  --cta-body-text-align: unset;
  --cta-background-image-position: absolute;
  --cta-display: block;
  --cta-flex-wrap: initial;
  --cta-content-display: block;
  --cta-content-flex-direction: initial;
  --cta-content-justify-content: initial;
  --cta-content-align-items: initial;
  --cta-content-padding: 32px;
  --highlight-display: flex;
  --highlight-flex-direction: column;
  --highlight-align-items: center;
  --highlight-justify-content: center;
  --highlight-content-text-align: center;
  --highlight-justify-content: normal;
  --highlight-content-max-width: 520px;
  --highlight-content-margin: 16px auto 0;
  --highlight-title-margin: 8px 0;
  --highlight-button-margin-top: 12px;
  --highlight-border-radius: var(--item-border-radius);
  --highlight-padding: 0;
  --highlight-background: transparent;
  --highlight-height: auto;
  --highlight-view-button-margin-top: 16px;
  --contact-info-container-flex-direction: column;
  --contact-info-container-justify-content: center;
  --contact-info-container-align-items: center;
  --contact-info-item-flex-direction: column;
  --contact-info-item-justify-content: center;
  --contact-info-item-align-items: center;
  --contact-info-item-border: unset;
  --contact-info-item-border-width: unset;
  --contact-info-item-border-radius: 0;
  --contact-info-item-margin: 0 0 24px;
  --contact-info-item-padding: 0;
  --contact-info-item-width: unset;
  --contact-info-item-details-text-align: center;
  --contact-info-item-details-margin: 16px 0 0;
  --form-container-justify-content: center;
  --form-container-align-items: center;
  --form-container-flex-direction: column;
  --form-container-text-align: center;
  --form-container-align-items: normal;
  --form-container-height: auto;
  --form-justify-content: center;
  --form-align-items: center;
  --form-flex-direction: column;
  --form-input-width: 100%;
  --form-input-margin: 0 auto 16px;
  --form-helper-text-margin: 16px 0 0;
  --form-button-margin: 0 auto;
  --input-border-radius: 3px;
  --input-border: 1px solid #ddd;
  --input-padding: 12px;
  --input-focus-border-color: #278ed4;
  --service-time-container-margin: 16px 0 0 0;
  --service-times-item-gap: 64px;
  --service-times-item-width: 100%;
  --service-times-item-padding: initial;
  --service-times-item-box-shadow: initial;
  --service-times-item-border-radius: initial;
  --service-times-item-border: initial;
  --service-times-item-text-align: center;
  --service-times-item-flex-direction: column;
  --service-times-item-justify-content: flex-start;
  --service-times-item-align-items: center;
  --service-times-item-time-top-margin: 12px;
  --service-times-item-time-bottom-margin: 24px;
  --service-times-inline-day-display: none;
  --service-times-icon-display: block;
  --service-times-flex-direction: row;
  --service-times-justify-content: center;
  --service-time-container-justify-content: flex-start;
  --service-time-container-display: flex;
  --service-time-container-flex-direction: column;
  --service-time-container-align-items: center;
  --service-time-container-text-align: center;
  --gallery-grid-row-gap: 32px;
  --gallery-grid-column-gap: 32px;
  --gallery-grid-template-columns: 1fr;
  --grid-item-background: initial;
  --grid-item-border-width: 1px;
  --grid-item-border-radius: var(--item-border-radius);
  --grid-item-overflow: inherit;
  --grid-item-padding: 32px;
  --grid-item-date-margin: 0 0 .5rem;
  --grid-item-title-margin: 16px 0 0;
  --grid-item-title-text-align: center;
  --grid-item-details-margin: 24px 0 0 0;
  --grid-item-heading-flex: 1 0 100%;
  --grid-item-subtitle-margin: 12px 0 0 0;
  --grid-item-button-container-margin: auto 0 0 0;
  --grid-item-button-container-padding: 16px 0 0 0;
  --grid-row-gap: 24px;
  --grid-column-gap: 32px;
  --list-item-text-align: left;
  --blog-item-image-border-radius: initial;
  --blog-item-image-height: 100%;
  --blog-item-image-max-height: 220px;
  --blog-grid-item-preview-margin: 12px 0;
  --blog-grid-item-preview-font-size: inherit;
  --blog-item-details-margin: 16px;
  --blog-list-item-display: block;
  --blog-list-image-width: 100%;
  --blog-highlight-display: block;
  --sermon-grid-item-placeholder-height: 200px;
  --sermon-grid-item-media-container-margin: 0 0 12px 0;
  --sermon-grid-item-title-margin: 8px 0;
  --sermon-grid-item-media-container-border-radius: 0;
  --sermon-grid-item-details-margin: 0;
  --verse-image-border-radius: var(--item-border-radius);
  --header-dropdown-box-shadow: rgba(0,0,0,0.5) 0 10px 20px 0;
  --header-dropdown-text-transform: uppercase;
  --header-dropdown-border-radius: 0;
  --header-dropdown-border: 0;
  --header-icon-border-radius: 5px;
  --header-default-icon-color: #7a7a7a;
  --header-menu-item-text-transform: uppercase;
  --shared-menu-margin: 0 0 0 auto;
  --mobile-header-justify-content: center;
  --mobile-header-flex-direction: column;
  --mobile-menu-top-position: 0;
  --mobile-menu-width: 300px;
  --mobile-menu-left-hidden-position: auto;
  --mobile-menu-right-hidden-position: calc(-1 * var(--mobile-menu-width));
  --mobile-menu-left-visible-position: auto;
  --mobile-menu-right-visible-position: 0;
  --mobile-menu-transition: right 400ms ease;
  --mobile-menu-box-shadow: rgba(0,0,0,0.4) 0 10px 20px 0;
  --mobile-nav-hamburger-top-position: 50%;
  --mobile-nav-hamburger-left-position: unset;
  --mobile-nav-hamburger-right-position: 12px;
  --mobile-nav-hamburger-transform: translateY(-50%);
  --mobile-nav-button-text-display: none;
  --mobile-menu-display: flex;
  --desktop-header-display: none;
  --group-branding-margin: 8px;
  --group-branding-vertical-margin: 0 8px;
  --group-branding-icon-margin: 16px;
  --content-container-margin: 0 16px;
  --content-container-max-width: 540px;
  --mosaic-item-width: 100%;
  --mosaic-item-margin: 0 0 32px 0;
  --hero-text-align: center;
  --hero-flex-direction: column;
  --hero-align-items: center;
  --hero-buttons-align-items: center;
  --hero-buttons-flex-direction: center;
  --hero-justify-content: center;
  --hero-buttons-justify-content: center;
  --periodical-grid-flex-direction: column;
  --periodical-grid-display: flex;
  --periodical-grid-padding: 48px 0;
  --periodical-grid-align-items: center;
  --periodical-grid-margin: 0;
  --periodical-grid-text-align: left;
  --periodical-grid-justify-content: left;
  --blog-item-details-display: flex;
  --blog-item-details-justify-content: flex-start;
  --blog-item-details-align-items: flex-start;
  --blog-item-details-text-align: left;
  --blog-item-details-flex-direction: column;
  --menu-item-font-family: var(--heading-font-family);
  --menu-item-font-size: var(--heading-font-size);
  --menu-item-font-weight: var(--heading-font-weight);
  --menu-subitem-font-family: var(--heading-font-family);
  --menu-subitem-font-size: var(--heading-font-size);
  --menu-subitem-font-weight: var(--heading-font-weight);
  --menu-box-shadow: rgba(0,0,0,0.4) 0 10px 20px 0
}

@media (max-width: 576px) {
  :root {
    --mobile-menu-width:100vw
  }
}

@media (min-width: 576px) {
  :root {
    --content-container-margin:var(--content-container-margin-xs, 0 auto);
    --content-container-max-width: var(--content-container-max-width-xs, 540px)
  }
}

@media (min-width: 768px) {
  :root {
    --content-container-max-width:var(--content-container-max-width-sm, 720px);
    --display-font-size: 56px;
    --h2-font-size: 48px;
    --mosaic-item-width: 46%;
    --list-item-details-flex-direction: row;
    --list-item-details-align-items: center;
    --button-list-margin: 0
  }
}

@media (min-width: 992px) {
  :root {
    --content-container-max-width:var(--content-container-max-width-md, 960px);
    --desktop-header-display: flex;
    --mobile-menu-display: none;
    --mosaic-item-width: 31%
  }
}

@media (min-width: 1200px) {
  :root {
    --content-container-max-width:var(--content-container-max-width-lg, 1140px)
  }
}

@media (pointer: coarse) {
  :root {
    --slideout-menu-padding:0 0 80px
  }
}

.fragment-form-container {
  --fragment-title-margin-bottom: 16px
}

.fragment-form-container .status-message {
  color: var(--text-color)
}

.newsletter-signup-form .status-message {
  margin-top: 16px
}

.sermons-fragment {
  --grid-item-border: none;
  --grid-item-padding: 0;
  --list-item-container-flex-direction: row;
  --list-item-details-margin: 0 0 0 16px
}

.sermons-fragment .fragment-highlight .sermons__tags {
  justify-content: center
}

.sermons-fragment .fragment-highlight .highlight-view-button {
  margin-bottom: 48px
}

.sermons-fragment .multimedia-embed-wrapper {
  --multimedia-embed-background: transparent
}

.sermons-fragment .multimedia-embed-wrapper img {
  object-fit: contain
}

.blog-details .multimedia-embed-wrapper {
  background: unset
}

.blog-details .multimedia-embed-wrapper img {
  object-fit: contain
}

.events,.event-details {
  --highlight-title-margin: 4px 0 8px;
  --event-highlight-details-max-width: 80%;
  --event-highlight-details-margin: 8px 0 0 0;
  --list-item-container-flex-direction: row;
  --list-item-details-margin: 0 0 0 16px;
  --event-details-display: none;
  --event-date-inline-display: none;
  --event-date-square-text-color: var(--icon-color);
  --event-date-square-display: flex;
  --event-date-square-justify-content: center;
  --event-date-square-border: 1px solid var(--icon-border-color);
  --event-date-square-border-radius: 5px;
  --event-date-square-container-diameter: 66px;
  --event-date-square-background: transparent;
  --event-date-square-multi-day-divider: 1px solid var(--icon-border-color);
  --event-date-square-multi-day-top-padding: 2px;
  --event-date-month-padding: 0;
  --event-date-month-background: transparent;
  --event-date-small-font-size: 12px;
  --event-date-day-font-size: 32px;
  --event-item-location-margin: 0 0 4px 0;
  --list-item-subtitle-display: block;
  --event-item-location-date-divider: ''
}

.event-details {
  --event-date-square-container-diameter: 90px;
  --event-date-square-margin: 0 auto 24px
}

.document-list {
  --item-icon-border-radius: 0;
  --item-icon-container-diameter: 32px;
  --list-item-details-margin: 0 0 0 16px;
  --list-item-container-flex-direction: row
}

.blog-posts {
  --grid-item-border-width: 0;
  --grid-item-padding: 0;
  --highlight-image-container-margin: 0;
  --highlight-title-margin: 16px 0 4px;
  --highlight-content-max-width: none;
  --highlight-content-text-align: left;
  --highlight-content-margin: 0
}

.blog-highlight:not(.blog-highlight--no-image) {
  --highlight-padding: 0;
  --highlight-content-margin: var(--blog-item-details-margin)
}

.blog-highlight--no-image {
  --blog-highlight-background-width: 0;
  --blog-highlight-background-height: 0;
  --blog-highlight-grid-template-columns: 1fr;
  --highlight-content-text-align: center;
  --highlight-content-margin: 0 auto
}

.cta {
  --fragment-title-margin-bottom: 16px
}

.cta--with-image,.fragment-highlight--with-image {
  --background-image-max-height: 1000px
}

.mobile-download-cta {
  color: var(--mobile-download-cta-text-color)
}

.light-background,.dark-background {
  --fragment-title-text-color: var(--text-color);
  --list-item-subtitle-color: var(--text-color);
  --blog-grid-item-title-color: var(--text-color);
  --grid-item-border: solid var(--fill-2-color);
  --blog-item-image-container-background: var(--fill-1-color);
  --mobile-download-cta-text-color: var(--text-color);
  --event-date-square-text-color: var(--icon-color);
  --button-secondary-text-color: var(--hyperlink-color);
  --menu-background-color: white;
  --menu-border-radius: var(--item-border-radius);
  --menu-width: 300px
}

.light-background .sermons-fragment,.dark-background .sermons-fragment {
  --multimedia-embed-background: var(--image-placeholder-color)
}

.light-background .sermons-fragment {
  --sermon-icon-color: var(--icon-light-color)
}

.dark-background .sermons-fragment {
  --sermon-icon-color: var(--icon-dark-color)
}

.dark-background .mobile-nav,.dark-background .slideout {
  --menu-item-color: var(--menu-item-light-color);
  --menu-item-hover-color: var(--menu-item-light-hover-color);
  --menu-subitem-color: var(--menu-item-light-color);
  --menu-subitem-hover-color: var(--menu-item-hover-color)
}

.light-background .mobile-nav,.light-background .slideout {
  --menu-item-color: var(--menu-item-dark-color);
  --menu-item-hover-color: var(--menu-item-dark-hover-color);
  --menu-subitem-color: var(--menu-item-dark-color);
  --menu-subitem-hover-color: var(--menu-item-hover-color)
}

.mobile-nav,.slideout {
  --menu-subitem-hover-background-color: transparent;
  --menu-item-hover-background-color: transparent;
  --menu-subitem-padding: 12px 32px
}

@media (min-width: 768px) {
  .fragment-12-col {
    --h1-font-size:64px;
    --h2-font-size: 48px;
    --h3-font-size: 32px;
    --h4-font-size: 24px;
    --fragment-title-font-size: 48px;
    --contact-info-container-flex-direction: row;
    --contact-info-container-align-items: flex-start;
    --contact-info-item-width: 33.33333333%;
    --contact-info-item-margin: 0;
    --form-flex-direction: row;
    --form-margin: 0 auto;
    --form-input-width: 280px;
    --form-button-margin: 0 16px;
    --form-input-margin: 0;
    --grid-item-heading-flex: 1 0;
    --grid-item-details-margin: 0 0 0 16px;
    --grid-template-columns: 1fr 1fr;
    --service-times-item-width: 33.33333333%;
    --list-item-flex-direction: row;
    --list-item-justify-content: space-between;
    --list-item-align-items: center;
    --list-item-container-flex-direction: row;
    --list-item-container-width: 100%;
    --list-item-details-margin: 0 24px;
    --blog-list-item-image-container-margin: 0 24px 0 0;
    --blog-list-item-display: flex;
    --blog-list-item-width: 33.33333333%;
    --blog-item-image-height: auto;
    --blog-list-image-width: 33.33333333%;
    --event-details-button-display: initial;
    --highlight-content-max-width: 75%;
    --fragment-padding: var(--fragment-padding-1col);
    --default-fragment-padding: var(--default-fragment-padding-1col);
    --default-fragment-padding-top: var(--default-fragment-padding-1col-top);
    --default-fragment-padding-right: var(--default-fragment-padding-1col-right);
    --default-fragment-padding-bottom: var(--default-fragment-padding-1col-bottom);
    --default-fragment-padding-left: var(--default-fragment-padding-1col-left)
  }

  .fragment-12-col .blog-posts {
    --highlight-flex-direction: row;
    --highlight-align-items: center;
    --highlight-image-container-width: 100%
  }

  .fragment-12-col .blog-posts--few-items {
    --grid-template-columns: 1fr 1fr
  }

  .fragment-12-col .cta {
    --fragment-padding: var(--fragment-padding-1col);
    --default-fragment-padding: var(--default-fragment-padding-1col);
    --default-fragment-padding-top: var(--default-fragment-padding-1col-top);
    --default-fragment-padding-right: var(--default-fragment-padding-1col-right);
    --default-fragment-padding-bottom: var(--default-fragment-padding-1col-bottom);
    --default-fragment-padding-left: var(--default-fragment-padding-1col-left)
  }
}

@media (min-width: 768px) and (min-width:768px) {
  .fragment-12-col {
    --highlight-height:auto
  }

  .fragment-12-col .gallery {
    --gallery-grid-template-columns: repeat(2, minmax(0, 1fr))
  }
}

@media (min-width: 768px) and (min-width:992px) {
  .fragment-12-col {
    --list-item-container-width:80%;
    --item-icon-container-diameter: 54px;
    --blog-list-item-display: flex;
    --blog-highlight-preview-margin: 0 0 12px;
    --blog-highlight-display: grid;
    --blog-highlight-grid-template-columns: 1fr 1fr;
    --blog-highlight-grid-column-gap: 24px
  }

  .fragment-12-col .blog-list {
    --blog-item-image-height: auto
  }

  .fragment-12-col .blog-posts,.fragment-12-col .sermons-fragment {
    --grid-template-columns: 1fr 1fr 1fr
  }

  .fragment-12-col .events,.fragment-12-col .events-details,.fragment-12-col .sermons-fragment {
    --list-item-details-margin: 0 24px;
    --event-item-location-margin: 0 4px 12px 0;
    --list-item-subtitle-display: flex;
    --list-item-subtitle-flex-wrap: wrap;
    --event-item-location-date-divider: ' •'
  }

  .fragment-12-col .gallery {
    --gallery-grid-template-columns: repeat(3, minmax(0, 1fr))
  }
}

@media (min-width: 768px) {
  .fragment-6-col {
    --form-input-width:280px;
    --form-container-align-items: center;
    --form-container-height: 100%;
    --fragment-title-text-align: var(--fragment-title-text-align-2col, left);
    --contact-info-container-align-items: flex-start;
    --contact-info-item-margin: 0 0 24px;
    --contact-info-item-details-margin: 16px 0 0;
    --contact-info-item-details-text-align: var(--fragment-content-text-align-2col, var(--fragment-content-text-align, left));
    --contact-info-item-width: 100%;
    --contact-info-container-flex-direction: column;
    --contact-info-item-justify-content: flex-start;
    --service-times-item-flex-direction: row;
    --service-times-item-align-items: end;
    --service-times-item-text-align: var(--fragment-content-text-align-2col, var(--fragment-content-text-align, left));
    --service-time-container-margin: 0 0 0 16px;
    --service-time-container-align-items: flex-start;
    --service-time-container-text-align: left;
    --blog-list-image-width: 100%;
    --highlight-justify-content: center;
    --cta-content-text-align: var(--fragment-content-text-align-2col, var(--fragment-content-text-align, left));
    --list-item-details-flex-direction: column;
    --list-item-details-align-items: flex-start;
    --button-list-margin: 12px 0 0;
    --fragment-padding: var(--fragment-padding-2col);
    --default-fragment-padding: var(--default-fragment-padding-2col);
    --default-fragment-padding-top: var(--default-fragment-padding-2col-top);
    --default-fragment-padding-right: var(--default-fragment-padding-2col-right);
    --default-fragment-padding-bottom: var(--default-fragment-padding-2col-bottom);
    --default-fragment-padding-left: var(--default-fragment-padding-2col-left)
  }

  .fragment-6-col .events,.fragment-6-col .sermons-fragment {
    --list-item-subtitle-display: block
  }

  .fragment-6-col .fragment-highlight__title {
    --h2-font-size: 24px
  }

  .fragment-6-col .cta {
    --fragment-padding: var(--fragment-padding-2col);
    --default-fragment-padding: var(--default-fragment-padding-2col);
    --default-fragment-padding-top: var(--default-fragment-padding-2col-top);
    --default-fragment-padding-right: var(--default-fragment-padding-2col-right);
    --default-fragment-padding-bottom: var(--default-fragment-padding-2col-bottom);
    --default-fragment-padding-left: var(--default-fragment-padding-2col-left)
  }
}

@media (min-width: 768px) and (max-width:991px) {
  .fragment-4-col:not(.fragment-4-col--last) {
    --form-input-width:280px;
    --form-container-align-items: center;
    --form-container-height: 100%;
    --fragment-title-text-align: var(--fragment-title-text-align-2col, left);
    --contact-info-container-align-items: flex-start;
    --contact-info-item-margin: 0 0 24px;
    --contact-info-item-details-margin: 16px 0 0;
    --contact-info-item-details-text-align: var(--fragment-content-text-align-2col, var(--fragment-content-text-align, left));
    --contact-info-item-width: 100%;
    --contact-info-container-flex-direction: column;
    --contact-info-item-justify-content: flex-start;
    --service-times-item-flex-direction: row;
    --service-times-item-align-items: end;
    --service-times-item-text-align: var(--fragment-content-text-align-2col, var(--fragment-content-text-align, left));
    --service-time-container-margin: 0 0 0 16px;
    --service-time-container-align-items: flex-start;
    --service-time-container-text-align: left;
    --blog-list-image-width: 100%;
    --highlight-justify-content: center;
    --cta-content-text-align: var(--fragment-content-text-align-2col, var(--fragment-content-text-align, left));
    --list-item-details-flex-direction: column;
    --list-item-details-align-items: flex-start;
    --button-list-margin: 12px 0 0;
    --fragment-padding: var(--fragment-padding-2col);
    --default-fragment-padding: var(--default-fragment-padding-2col);
    --default-fragment-padding-top: var(--default-fragment-padding-2col-top);
    --default-fragment-padding-right: var(--default-fragment-padding-2col-right);
    --default-fragment-padding-bottom: var(--default-fragment-padding-2col-bottom);
    --default-fragment-padding-left: var(--default-fragment-padding-2col-left)
  }

  .fragment-4-col:not(.fragment-4-col--last) .events,.fragment-4-col:not(.fragment-4-col--last) .sermons-fragment {
    --list-item-subtitle-display: block
  }

  .fragment-4-col:not(.fragment-4-col--last) .fragment-highlight__title {
    --h2-font-size: 24px
  }

  .fragment-4-col:not(.fragment-4-col--last) .cta {
    --fragment-padding: var(--fragment-padding-2col);
    --default-fragment-padding: var(--default-fragment-padding-2col);
    --default-fragment-padding-top: var(--default-fragment-padding-2col-top);
    --default-fragment-padding-right: var(--default-fragment-padding-2col-right);
    --default-fragment-padding-bottom: var(--default-fragment-padding-2col-bottom);
    --default-fragment-padding-left: var(--default-fragment-padding-2col-left)
  }
}

@media (min-width: 992px) {
  .fragment-4-col:not(.fragment-4-col--last) {
    --h4-font-size:18px;
    --h5-font-size: 16px;
    --fragment-title-text-align: var(--fragment-title-text-align-3col, left);
    --fragment-title-margin-bottom: 24px;
    --contact-info-item-margin: 0 0 24px;
    --contact-info-item-flex-direction: row;
    --contact-info-item-details-margin: 0 0 0 16px;
    --contact-info-item-details-text-align: var(--fragment-content-text-align-3col, var(--fragment-content-text-align, left));
    --contact-info-container-justify-content: flex-start;
    --contact-info-container-flex-direction: column;
    --contact-info-item-justify-content: flex-start;
    --contact-info-container-align-items: center;
    --contact-info-item-width: 100%;
    --service-times-item-flex-direction: row;
    --service-times-item-align-items: end;
    --service-times-item-text-align: var(--fragment-content-text-align-3col, var(--fragment-content-text-align, left));
    --service-time-container-margin: 0 0 0 16px;
    --service-times-item-gap: 32px;
    --form-align-items: flex-start;
    --form-container-text-align: var(--fragment-content-text-align-3col, var(--fragment-content-text-align, left));
    --form-button-margin: 0;
    --list-item-details-flex-direction: column;
    --list-item-details-align-items: flex-start;
    --button-list-margin: 12px 0 0;
    --fragment-padding: var(--fragment-padding-3col);
    --default-fragment-padding: var(--default-fragment-padding-3col);
    --default-fragment-padding-top: var(--default-fragment-padding-3col-top);
    --default-fragment-padding-right: var(--default-fragment-padding-3col-right);
    --default-fragment-padding-bottom: var(--default-fragment-padding-3col-bottom);
    --default-fragment-padding-left: var(--default-fragment-padding-3col-left)
  }

  .fragment-4-col:not(.fragment-4-col--last) .events,.fragment-4-col:not(.fragment-4-col--last) .sermons-fragment {
    --list-item-subtitle-display: block
  }

  .fragment-4-col:not(.fragment-4-col--last) .cta {
    --fragment-padding: var(--fragment-padding-3col);
    --default-fragment-padding: var(--default-fragment-padding-3col);
    --default-fragment-padding-top: var(--default-fragment-padding-3col-top);
    --default-fragment-padding-right: var(--default-fragment-padding-3col-right);
    --default-fragment-padding-bottom: var(--default-fragment-padding-3col-bottom);
    --default-fragment-padding-left: var(--default-fragment-padding-3col-left)
  }

  .fragment-4-col:not(.fragment-4-col--last) .fragment-highlight__title {
    --h2-font-size: 24px
  }
}

@media (min-width: 992px) and (min-width:768px) {
  .fragment-4-col:not(.fragment-4-col--last) {
    --event-item-location-date-divider:' •'
  }
}

@media (min-width: 768px) and (max-width:991px) {
  .fragment-4-col--last {
    --h1-font-size:64px;
    --h2-font-size: 48px;
    --h3-font-size: 32px;
    --h4-font-size: 24px;
    --fragment-title-font-size: 48px;
    --contact-info-container-flex-direction: row;
    --contact-info-container-align-items: flex-start;
    --contact-info-item-width: 33.33333333%;
    --contact-info-item-margin: 0;
    --form-flex-direction: row;
    --form-margin: 0 auto;
    --form-input-width: 280px;
    --form-button-margin: 0 16px;
    --form-input-margin: 0;
    --grid-item-heading-flex: 1 0;
    --grid-item-details-margin: 0 0 0 16px;
    --grid-template-columns: 1fr 1fr;
    --service-times-item-width: 33.33333333%;
    --list-item-flex-direction: row;
    --list-item-justify-content: space-between;
    --list-item-align-items: center;
    --list-item-container-flex-direction: row;
    --list-item-container-width: 100%;
    --list-item-details-margin: 0 24px;
    --blog-list-item-image-container-margin: 0 24px 0 0;
    --blog-list-item-display: flex;
    --blog-list-item-width: 33.33333333%;
    --blog-item-image-height: auto;
    --blog-list-image-width: 33.33333333%;
    --event-details-button-display: initial;
    --highlight-content-max-width: 75%;
    --fragment-padding: var(--fragment-padding-1col);
    --default-fragment-padding: var(--default-fragment-padding-1col);
    --default-fragment-padding-top: var(--default-fragment-padding-1col-top);
    --default-fragment-padding-right: var(--default-fragment-padding-1col-right);
    --default-fragment-padding-bottom: var(--default-fragment-padding-1col-bottom);
    --default-fragment-padding-left: var(--default-fragment-padding-1col-left)
  }

  .fragment-4-col--last .blog-posts {
    --highlight-flex-direction: row;
    --highlight-align-items: center;
    --highlight-image-container-width: 100%
  }

  .fragment-4-col--last .blog-posts--few-items {
    --grid-template-columns: 1fr 1fr
  }

  .fragment-4-col--last .cta {
    --fragment-padding: var(--fragment-padding-1col);
    --default-fragment-padding: var(--default-fragment-padding-1col);
    --default-fragment-padding-top: var(--default-fragment-padding-1col-top);
    --default-fragment-padding-right: var(--default-fragment-padding-1col-right);
    --default-fragment-padding-bottom: var(--default-fragment-padding-1col-bottom);
    --default-fragment-padding-left: var(--default-fragment-padding-1col-left)
  }
}

@media (min-width: 768px) and (max-width:991px) and (min-width:768px) {
  .fragment-4-col--last {
    --highlight-height:auto
  }

  .fragment-4-col--last .gallery {
    --gallery-grid-template-columns: repeat(2, minmax(0, 1fr))
  }
}

@media (min-width: 768px) and (max-width:991px) and (min-width:992px) {
  .fragment-4-col--last {
    --list-item-container-width:80%;
    --item-icon-container-diameter: 54px;
    --blog-list-item-display: flex;
    --blog-highlight-preview-margin: 0 0 12px;
    --blog-highlight-display: grid;
    --blog-highlight-grid-template-columns: 1fr 1fr;
    --blog-highlight-grid-column-gap: 24px
  }

  .fragment-4-col--last .blog-list {
    --blog-item-image-height: auto
  }

  .fragment-4-col--last .blog-posts,.fragment-4-col--last .sermons-fragment {
    --grid-template-columns: 1fr 1fr 1fr
  }

  .fragment-4-col--last .events,.fragment-4-col--last .events-details,.fragment-4-col--last .sermons-fragment {
    --list-item-details-margin: 0 24px;
    --event-item-location-margin: 0 4px 12px 0;
    --list-item-subtitle-display: flex;
    --list-item-subtitle-flex-wrap: wrap;
    --event-item-location-date-divider: ' •'
  }

  .fragment-4-col--last .gallery {
    --gallery-grid-template-columns: repeat(3, minmax(0, 1fr))
  }
}

@media (min-width: 992px) {
  .fragment-4-col--last {
    --h4-font-size:18px;
    --h5-font-size: 16px;
    --fragment-title-text-align: var(--fragment-title-text-align-3col, left);
    --fragment-title-margin-bottom: 24px;
    --contact-info-item-margin: 0 0 24px;
    --contact-info-item-flex-direction: row;
    --contact-info-item-details-margin: 0 0 0 16px;
    --contact-info-item-details-text-align: var(--fragment-content-text-align-3col, var(--fragment-content-text-align, left));
    --contact-info-container-justify-content: flex-start;
    --contact-info-container-flex-direction: column;
    --contact-info-item-justify-content: flex-start;
    --contact-info-container-align-items: center;
    --contact-info-item-width: 100%;
    --service-times-item-flex-direction: row;
    --service-times-item-align-items: end;
    --service-times-item-text-align: var(--fragment-content-text-align-3col, var(--fragment-content-text-align, left));
    --service-time-container-margin: 0 0 0 16px;
    --service-times-item-gap: 32px;
    --form-align-items: flex-start;
    --form-container-text-align: var(--fragment-content-text-align-3col, var(--fragment-content-text-align, left));
    --form-button-margin: 0;
    --list-item-details-flex-direction: column;
    --list-item-details-align-items: flex-start;
    --button-list-margin: 12px 0 0;
    --fragment-padding: var(--fragment-padding-3col);
    --default-fragment-padding: var(--default-fragment-padding-3col);
    --default-fragment-padding-top: var(--default-fragment-padding-3col-top);
    --default-fragment-padding-right: var(--default-fragment-padding-3col-right);
    --default-fragment-padding-bottom: var(--default-fragment-padding-3col-bottom);
    --default-fragment-padding-left: var(--default-fragment-padding-3col-left)
  }

  .fragment-4-col--last .events,.fragment-4-col--last .sermons-fragment {
    --list-item-subtitle-display: block
  }

  .fragment-4-col--last .cta {
    --fragment-padding: var(--fragment-padding-3col);
    --default-fragment-padding: var(--default-fragment-padding-3col);
    --default-fragment-padding-top: var(--default-fragment-padding-3col-top);
    --default-fragment-padding-right: var(--default-fragment-padding-3col-right);
    --default-fragment-padding-bottom: var(--default-fragment-padding-3col-bottom);
    --default-fragment-padding-left: var(--default-fragment-padding-3col-left)
  }

  .fragment-4-col--last .fragment-highlight__title {
    --h2-font-size: 24px
  }
}

@media (min-width: 992px) and (min-width:768px) {
  .fragment-4-col--last {
    --event-item-location-date-divider:' •'
  }
}

.footer {
  --fragment-content-box-shadow: none;
  --fragment-box-shadow: none;
  --edit-item-left: 2px;
  --edit-item-top: 2px
}

.footer .fragment-title {
  --fragment-title-margin-bottom: 24px
}

.footer .footer__sections {
  --h4-font-size: var(--body-text-medium-font-size);
  --fragment-padding: var(--fragment-padding-footer, 0);
  --default-fragment-padding: var(--default-fragment-padding-footer, 0);
  --fragment-title-font-size: 20px;
  --fragment-title-text-transform: uppercase;
  --fragment-content-background: transparent;
  --fragment-content-padding: 0;
  --item-icon-display: none;
  --service-times-icon-display: none;
  --service-times-inline-day-display: inline-block;
  --service-time-container-margin: 0;
  --service-time-container-align-items: left;
  --service-time-container-text-align: left;
  --service-times-item-gap: 16px;
  --service-times-item-time-top-margin: 4px;
  --service-times-item-time-bottom-margin: 12px;
  --contact-info-item-details-margin: 0;
  --contact-info-item-margin: 0 0 16px 0;
  --hyperlink-color: var(--text-color);
  --fragment-title-text-align: left;
  --form-container-justify-content: flex-start;
  --form-container-align-items: flex-start;
  --form-align-items: flex-start;
  --form-input-width: 100%;
  --form-button-margin: 0;
  --contact-info-item-justify-content: normal;
  --contact-info-item-align-items: flex-start;
  --contact-info-container-align-items: flex-start;
  --contact-info-item-details-text-align: left;
  --service-times-item-text-align: left;
  --service-times-item-align-items: flex-start;
  --cta-body-text-align: left;
  --cta-content-text-align: left;
  --cta-content-padding: 0;
  --fragment-padding-2col: var(--fragment-padding-footer, 0);
  --fragment-padding-3col: var(--fragment-padding-footer, 0);
  --default-fragment-padding-2col: var(--default-fragment-padding-footer, 0);
  --default-fragment-padding-3col: var(--default-fragment-padding-footer, 0)
}

.not-found-page .page-header,.error-page .page-header {
  display: block
}

.fragment {
  position: relative;
  display: flex;
  flex-direction: column;
  background: var(--fragment-background);
  box-shadow: var(--fragment-box-shadow);
  border: var(--fragment-border);
  border-width: var(--fragment-border-width);
  border-radius: var(--fragment-border-radius);
  overflow: var(--fragment-overflow);
  width: 100%;
  height: 100%;
  color: var(--text-color)
}

.fragment__content {
  background: var(--fragment-content-background);
  box-shadow: var(--fragment-content-box-shadow);
  border: var(--fragment-content-border);
  border-width: var(--fragment-content-border-width);
  border-radius: var(--fragment-content-border-radius);
  padding: var(--fragment-content-padding)
}

.fragment-section {
  margin: var(--content-container-margin);
  max-width: var(--content-container-max-width)
}

.legacy-fragment-padding .fragment,.legacy-fragment-padding .events__cta,.legacy-fragment-padding .fragment-cta {
  padding: var(--fragment-padding)
}

.fragment-cta {
  background: var(--fragment-background);
  box-shadow: var(--fragment-cta-box-shadow);
  border: var(--fragment-border);
  border-width: var(--fragment-border-width);
  border-radius: var(--fragment-border-radius);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: var(--fragment-cta-justify-content);
  align-items: var(--fragment-cta-align-items)
}

.fragment-cta__icon {
  color: var(--icon-color)
}

.fragment-cta__title {
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  color: var(--text-color);
  margin-bottom: 4px;
  font-size: var(--h3-font-size);
  line-height: var(--heading-line-height);
  margin: var(--fragment-cta-title-margin)
}

.fragment-cta__subtitle {
  font-family: var(--ui-font-family);
  font-weight: var(--ui-font-weight);
  line-height: var(--ui-line-height);
  font-size: var(--ui-large-size)
}

.fragment-cta__button {
  font-family: var(--ui-font-family);
  font-weight: var(--ui-font-weight);
  line-height: var(--ui-line-height);
  font-size: var(--ui-medium-size);
  line-height: 1;
  display: var(--button-display);
  box-shadow: var(--button-box-shadow);
  border: var(--button-border);
  border-color: var(--button-primary-color);
  border-radius: var(--button-border-radius);
  padding: var(--button-padding);
  background: var(--button-primary-color);
  color: var(--button-primary-text-color);
  min-width: var(--button-min-width);
  cursor: pointer;
  text-decoration: none;
  transition: var(--button-transition);
  white-space: nowrap;
  text-align: center;
  border: var(--button-secondary-border);
  border-color: var(--button-secondary-color);
  background-color: var(--button-secondary-background);
  color: var(--button-secondary-text-color);
  margin-top: var(--fragment-cta-button-margin-top)
}

.fragment-cta__button:hover {
  border-color: var(--button-primary-hover-color);
  background-color: var(--button-primary-hover-color);
  color: var(--button-primary-hover-text-color);
  text-decoration: none
}

.fragment-cta__button:hover {
  border-color: var(--button-secondary-hover-color);
  background: var(--button-secondary-hover-color);
  color: var(--button-secondary-hover-text-color)
}

.fragment-form-button,.highlight-view-button {
  font-family: var(--ui-font-family);
  font-weight: var(--ui-font-weight);
  line-height: var(--ui-line-height);
  font-size: var(--ui-medium-size);
  line-height: 1;
  display: var(--button-display);
  box-shadow: var(--button-box-shadow);
  border: var(--button-border);
  border-color: var(--button-primary-color);
  border-radius: var(--button-border-radius);
  padding: var(--button-padding);
  background: var(--button-primary-color);
  color: var(--button-primary-text-color);
  min-width: var(--button-min-width);
  cursor: pointer;
  text-decoration: none;
  transition: var(--button-transition);
  white-space: nowrap;
  text-align: center;
  margin-top: var(--highlight-view-button-margin-top)
}

.fragment-form-button:hover,.highlight-view-button:hover {
  border-color: var(--button-primary-hover-color);
  background-color: var(--button-primary-hover-color);
  color: var(--button-primary-hover-text-color);
  text-decoration: none
}

.fragment-form-button:hover,.highlight-view-button:hover {
  border-color: var(--button-primary-hover-color);
  background-color: var(--button-primary-hover-color);
  color: var(--button-primary-hover-text-color);
  text-decoration: none
}

.fragment-highlight {
  display: flex;
  flex-direction: var(--highlight-flex-direction);
  align-items: var(--highlight-align-items);
  justify-content: var(--highlight-justify-content);
  box-shadow: var(--item-box-shadow);
  border-radius: var(--item-border-radius);
  padding: var(--highlight-padding);
  background: var(--highlight-background);
  height: var(--highlight-height)
}

.fragment-highlight--with-image {
  padding: var(--background-image-padding);
  background-position: var(--fragment-image-background-position);
  background-size: var(--fragment-image-background-size)
}

.fragment-highlight__date {
  margin-bottom: 0;
  color: var(--text-color)
}

.fragment-highlight__title {
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  margin-bottom: 8px;
  font-size: var(--h2-font-size);
  line-height: var(--heading-line-height);
  color: var(--text-color);
  display: block;
  margin: var(--highlight-title-margin)
}

.fragment-highlight__title:hover {
  color: var(--hyperlink-hover-color)
}

.fragment-highlight__subtitle {
  font-family: var(--body-font-family);
  font-weight: var(--body-font-weight);
  line-height: var(--body-text-line-height);
  font-size: var(--body-text-medium-font-size);
  color: var(--text-color)
}

.fragment-highlight__content {
  text-align: var(--highlight-content-text-align, var(--fragment-content-text-align));
  max-width: var(--highlight-content-max-width);
  margin: var(--highlight-content-margin)
}

.fragment-list {
  display: flex;
  flex-direction: column
}

.fragment-title {
  font-size: var(--fragment-title-font-size);
  line-height: var(--fragment-title-line-height);
  letter-spacing: var(--fragment-title-letter-spacing);
  text-transform: var(--fragment-title-text-transform);
  margin-bottom: var(--fragment-title-margin-bottom);
  text-align: var(--fragment-title-text-align);
  color: var(--fragment-title-text-color)
}

.fragment-view-all {
  margin-top: var(--fragment-view-all-margin-top);
  text-align: var(--fragment-view-all-text-align, var(--fragment-content-text-align))
}

.fragment-view-all__text {
  font-family: var(--ui-font-family);
  font-weight: var(--ui-font-weight);
  line-height: var(--ui-line-height);
  font-size: var(--ui-medium-size);
  line-height: 1;
  display: var(--button-display);
  box-shadow: var(--button-box-shadow);
  border: var(--button-border);
  border-color: var(--button-primary-color);
  border-radius: var(--button-border-radius);
  padding: var(--button-padding);
  background: var(--button-primary-color);
  color: var(--button-primary-text-color);
  min-width: var(--button-min-width);
  cursor: pointer;
  text-decoration: none;
  transition: var(--button-transition);
  white-space: nowrap;
  text-align: center;
  border: var(--button-secondary-border);
  border-color: var(--button-secondary-color);
  background-color: var(--button-secondary-background);
  color: var(--button-secondary-text-color)
}

.fragment-view-all__text:hover {
  border-color: var(--button-primary-hover-color);
  background-color: var(--button-primary-hover-color);
  color: var(--button-primary-hover-text-color);
  text-decoration: none
}

.fragment-view-all__text:hover {
  border-color: var(--button-secondary-hover-color);
  background: var(--button-secondary-hover-color);
  color: var(--button-secondary-hover-text-color)
}

.fragment-item-icon-container {
  display: var(--item-icon-display);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: var(--item-icon-border);
  border-color: var(--icon-border-color);
  border-radius: var(--item-icon-border-radius);
  width: var(--item-icon-container-diameter);
  height: var(--item-icon-container-diameter);
  background-color: var(--item-icon-background-color);
  color: var(--icon-color)
}

a.fragment-item-icon-container:hover {
  border-color: var(--icon-hover-color)
}

a.fragment-item-icon-container .fragment-item-icon {
  color: var(--icon-color)
}

a.fragment-item-icon-container .fragment-item-icon:hover {
  color: var(--icon-color)
}

.fragment-grid {
  display: grid;
  padding: var(--grid-padding);
  grid-template-columns: var(--grid-template-columns);
  grid-column-gap: var(--grid-column-gap);
  grid-row-gap: var(--grid-row-gap)
}

.fragment-grid__item {
  background: var(--grid-item-background);
  border: var(--grid-item-border);
  border-width: var(--grid-item-border-width);
  border-radius: var(--grid-item-border-radius);
  padding: var(--grid-item-padding);
  box-shadow: var(--item-box-shadow);
  overflow: var(--grid-item-overflow)
}

.comfortable-fragment-padding .fragment,.comfortable-fragment-padding .events__cta,.comfortable-fragment-padding .fragment-cta {
  padding-top: var(--default-fragment-padding-top, var(--default-fragment-padding));
  padding-bottom: var(--default-fragment-padding-bottom, var(--default-fragment-padding));
  padding-left: var(--default-fragment-padding-left, var(--default-fragment-padding));
  padding-right: var(--default-fragment-padding-right, var(--default-fragment-padding))
}

.minimal-fragment-padding .fragment,.minimal-fragment-padding .events__cta,.minimal-fragment-padding .fragment-cta {
  padding-top: calc((var(--default-fragment-padding-top, var(--default-fragment-padding))) * var(--minimal-fragment-padding, .7));
  padding-bottom: calc((var(--default-fragment-padding-bottom, var(--default-fragment-padding))) * var(--minimal-fragment-padding, .7));
  padding-left: calc((var(--default-fragment-padding-left, var(--default-fragment-padding))) * var(--minimal-fragment-padding, .7));
  padding-right: calc((var(--default-fragment-padding-right, var(--default-fragment-padding))) * var(--minimal-fragment-padding, .7))
}

.spacious-fragment-padding .fragment,.spacious-fragment-padding .events__cta,.spacious-fragment-padding .fragment-cta {
  padding-top: calc((var(--default-fragment-padding-top, var(--default-fragment-padding))) * var(--spacious-fragment-padding, 1.3));
  padding-bottom: calc((var(--default-fragment-padding-bottom, var(--default-fragment-padding))) * var(--spacious-fragment-padding, 1.3));
  padding-left: calc((var(--default-fragment-padding-left, var(--default-fragment-padding))) * var(--spacious-fragment-padding, 1.3));
  padding-right: calc((var(--default-fragment-padding-right, var(--default-fragment-padding))) * var(--spacious-fragment-padding, 1.3))
}

.button-reset {
  border: none;
  padding: 0;
  background: transparent;
  color: inherit;
  overflow: visible
}

.button,.button--primary {
  font-family: var(--ui-font-family);
  font-weight: var(--ui-font-weight);
  line-height: var(--ui-line-height);
  font-size: var(--ui-medium-size);
  line-height: 1;
  display: var(--button-display);
  box-shadow: var(--button-box-shadow);
  border: var(--button-border);
  border-color: var(--button-primary-color);
  border-radius: var(--button-border-radius);
  padding: var(--button-padding);
  background: var(--button-primary-color);
  color: var(--button-primary-text-color);
  min-width: var(--button-min-width);
  cursor: pointer;
  text-decoration: none;
  transition: var(--button-transition);
  white-space: nowrap;
  text-align: center
}

.button:hover,.button--primary:hover {
  border-color: var(--button-primary-hover-color);
  background-color: var(--button-primary-hover-color);
  color: var(--button-primary-hover-text-color);
  text-decoration: none
}

.button--secondary {
  border: var(--button-secondary-border);
  border-color: var(--button-secondary-color);
  background-color: var(--button-secondary-background);
  color: var(--button-secondary-text-color)
}

.button--secondary:hover {
  border-color: var(--button-secondary-hover-color);
  background: var(--button-secondary-hover-color);
  color: var(--button-secondary-hover-text-color)
}

.button--list {
  font-family: var(--ui-font-family);
  font-weight: var(--ui-font-weight);
  line-height: var(--ui-line-height);
  font-size: var(--ui-medium-size);
  display: var(--button-list-display);
  align-items: var(--button-list-align-items);
  line-height: 1;
  border: var(--button-list-border);
  border-color: var(--button-list-color);
  border-radius: var(--button-list-border-radius);
  color: var(--button-secondary-text-color);
  background: transparent;
  padding: var(--button-list-padding);
  white-space: nowrap;
  text-align: center;
  width: -webkit-fit-content;
  width: fit-content;
  margin: var(--button-list-margin);
  height: -webkit-fit-content;
  height: fit-content
}

.button--list:hover {
  color: var(--button-list-hover-text-color);
  background: var(--button-list-hover-color);
  border-color: var(--button-list-hover-color)
}

.button--list:hover:link {
  text-decoration: none
}

.button--list svg {
  margin: var(--button-list-svg-margin)
}

.button-as-link {
  display: flex;
  align-items: center;
  box-shadow: none;
  border: none;
  cursor: pointer;
  padding: 0;
  min-width: auto;
  background-color: transparent;
  color: var(--button-secondary-text-color);
  text-transform: none
}

.button-as-link:hover,.button-as-link:focus {
  background-color: transparent;
  color: var(--hyperlink-hover-color)
}

.desktop-nav__list,.slideout-nav__list {
  margin-bottom: 0
}

.menu-link {
  font-size: var(--body-text-small-font-size);
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  color: var(--menu-item-color);
  text-transform: var(--menu-item-text-transform)
}

.menu-link:hover {
  color: var(--menu-item-hover-color);
  text-decoration: none
}

.desktop-nav__list {
  padding: 0;
  list-style-type: none
}

.desktop-nav__dropdown {
  width: 224px;
  box-shadow: var(--header-dropdown-box-shadow);
  text-transform: var(--header-dropdown-text-transform);
  border-radius: var(--header-dropdown-border-radius);
  border: var(--header-dropdown-border)
}

.desktop-nav__item {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  text-transform: var(--menu-item-text-transform)
}

.desktop-nav__dropdown-item::after {
  vertical-align: .15em
}

.desktop-nav__sub-item {
  padding: 12px;
  white-space: initial
}

.desktop-nav__sub-item:active,.desktop-nav__sub-item:focus,.desktop-nav__sub-item:hover {
  background-color: transparent
}

.shared-menus {
  display: flex;
  align-items: center;
  margin: var(--shared-menu-margin);
  position: relative
}

.shared-menus .slideout-menu {
  top: var(--mobile-menu-top-position);
  left: var(--mobile-menu-left-hidden-position);
  right: var(--mobile-menu-right-hidden-position);
  width: var(--mobile-menu-width);
  transition: var(--mobile-menu-transition);
  height: var(--slideout-menu-height);
  padding: var(--slideout-menu-padding);
  position: absolute;
  background-color: var(--mobile-menu-background-color, white)
}

.shared-menus .slideout-menu.show {
  left: var(--mobile-menu-left-visible-position);
  right: var(--mobile-menu-right-visible-position);
  box-shadow: var(--mobile-menu-box-shadow)
}

.shared-menus .slideout-menu__name {
  color: var(--menu-item-color)
}

.shared-menus .desktop-nav__menu-item {
  padding: 0
}

.shared-menus .desktop-nav__item-container {
  display: inline-flex;
  padding: 0 12px;
  align-items: center
}

.mobile-nav-container {
  position: relative
}

.shared-mobile-nav {
  display: var(--mobile-menu-display);
  justify-content: var(--mobile-header-justify-content);
  align-items: center;
  flex-direction: var(--mobile-header-flex-direction);
  min-height: var(--mobile-nav-height)
}

.shared-mobile-nav__icon {
  max-width: 235px;
  object-fit: contain
}

.shared-mobile-nav__name {
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  color: var(--text-color);
  font-size: var(--h5-font-size);
  line-height: var(--heading-line-height);
  color: var(--menu-item-color)
}

.shared-mobile-nav__name:hover {
  cursor: pointer;
  text-decoration: none;
  color: var(--menu-item-hover-color)
}

@media (min-width: 768px) {
  .shared-mobile-nav__name {
    margin-left:12px
  }
}

.shared-mobile-nav__header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap
}

.shared-mobile-nav__button {
  top: var(--mobile-nav-hamburger-top-position);
  left: var(--mobile-nav-hamburger-left-position);
  right: var(--mobile-nav-hamburger-right-position);
  transform: var(--mobile-nav-hamburger-transform);
  z-index: 999
}

.shared-mobile-nav__close-button {
  background: none;
  border: none;
  outline: none;
  position: absolute;
  width: 24px;
  right: 10px;
  top: 10px;
  height: 18px;
  cursor: pointer;
  z-index: 999
}

.shared-mobile-nav__close-button:active,.shared-mobile-nav__close-button:focus {
  outline: none
}

.fragment-periodical-list-item {
  display: flex;
  text-align: var(--list-item-text-align, var(--fragment-content-text-align));
  justify-content: var(--list-item-justify-content);
  flex-direction: var(--list-item-flex-direction);
  align-items: var(--list-item-align-items);
  box-shadow: var(--item-box-shadow);
  border: var(--list-item-border);
  border-width: var(--list-item-border-width);
  border-radius: var(--list-item-border-radius);
  background: var(--list-item-background);
  margin: var(--list-item-margin);
  padding: var(--list-item-padding)
}

.fragment-periodical-list-item:first-child {
  margin-top: 0;
  padding-top: var(--top-list-item-top-padding)
}

.fragment-periodical-list-item:last-child {
  margin-bottom: 0
}

.fragment-periodical-list-item__container {
  display: flex;
  flex-grow: 1;
  align-items: var(--list-item-container-align-items);
  flex-direction: var(--list-item-container-flex-direction);
  width: var(--list-item-container-width)
}

.fragment-periodical-list-item__date {
  margin-bottom: 0;
  color: var(--text-color)
}

.fragment-periodical-list-item__title {
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  font-size: var(--h4-font-size);
  line-height: var(--heading-line-height);
  color: var(--text-color)
}

.fragment-periodical-list-item__title:hover {
  color: var(--hyperlink-hover-color)
}

.fragment-periodical-list-item__details {
  margin: var(--list-item-details-margin);
  flex-direction: var(--list-item-details-flex-direction);
  align-items: var(--list-item-details-align-items);
  text-align: var(--list-item-text-align, var(--fragment-content-text-align));
  word-break: break-word;
  flex-grow: 1;
  display: flex;
  justify-content: space-between
}

.fragment-periodical-list-item__subtitle {
  display: var(--list-item-subtitle-display);
  flex-wrap: var(--list-item-subtitle-flex-wrap);
  font-family: var(--body-font-family);
  font-weight: var(--body-font-weight);
  line-height: var(--body-text-line-height);
  font-size: var(--body-text-medium-font-size);
  margin: var(--list-item-subtitle-margin);
  color: var(--list-item-subtitle-color)
}

.fragment-periodical-grid-item {
  display: var(--periodical-grid-display);
  flex-direction: var(--periodical-grid-flex-direction);
  word-break: break-word;
  padding: var(--periodical-grid-padding);
  margin: var(--periodical-grid-margin);
  align-items: var(--periodical-grid-align-items);
  text-align: var(--periodical-grid-text-align, var(--fragment-content-text-align));
  justify-content: var(--periodical-grid-justify-content)
}

.fragment-periodical-grid-item__heading {
  display: flex;
  flex-wrap: wrap;
  flex-direction: var(--periodical-grid-flex-direction);
  justify-content: var(--periodical-grid-justify-content);
  align-items: var(--periodical-grid-align-items)
}

.fragment-periodical-grid-item__title {
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  font-size: var(--h4-font-size);
  line-height: var(--heading-line-height);
  color: var(--text-color);
  text-align: var(--grid-item-title-text-align, var(--fragment-content-text-align))
}

.fragment-periodical-grid-item__title:hover {
  color: var(--hyperlink-hover-color)
}

.fragment-periodical-grid-item__details {
  margin: var(--grid-item-details-margin);
  flex: var(--grid-item-heading-flex);
  text-align: var(--periodical-grid-text-align, var(--fragment-content-text-align))
}

.fragment-periodical-grid-item__date {
  font-family: var(--body-font-family);
  font-weight: var(--body-font-weight);
  line-height: var(--body-text-line-height);
  font-size: var(--body-text-medium-font-size);
  margin: var(--grid-item-date-margin);
  color: var(--text-color)
}

.fragment-periodical-grid-item__subtitle {
  font-family: var(--body-font-family);
  font-weight: var(--body-font-weight);
  line-height: var(--body-text-line-height);
  font-size: var(--body-text-medium-font-size);
  margin: var(--grid-item-subtitle-margin);
  color: var(--text-color)
}

.fragment-periodical-grid-item__button-container {
  margin: var(--grid-item-button-container-margin);
  padding: var(--grid-item-button-container-padding)
}

.fragment-form-container {
  display: flex;
  justify-content: var(--form-container-justify-content);
  flex-direction: var(--form-container-flex-direction);
  text-align: var(--form-container-text-align, var(--fragment-content-text-align));
  align-items: var(--form-container-align-items);
  height: var(--form-container-height)
}

.fragment-form {
  display: flex;
  justify-content: var(--form-justify-content);
  align-items: var(--form-align-items);
  flex-direction: var(--form-flex-direction);
  margin: var(--form-margin)
}

.fragment-form__helper-text {
  margin: var(--form-helper-text-margin);
  color: var(--text-color)
}

.fragment-form .status-message {
  margin: var(--form-helper-text-margin);
  color: var(--text-color)
}

.fragment-form-input {
  width: var(--form-input-width);
  margin: var(--form-input-margin)
}

.fragment-form-button {
  margin: var(--form-button-margin)
}

.h1,h1,.h2,h2,.h3,h3,.h4,h4,.h5,h5,.h6,h6,.fragment-title,.page-header {
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  color: var(--text-color)
}

.display {
  margin-bottom: var(--display-margin-bottom);
  font-family: var(--display-font-family);
  font-weight: var(--display-font-weight);
  font-size: var(--display-font-size);
  line-height: var(--display-line-height)
}

h1 {
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  color: var(--text-color);
  margin-bottom: 8px;
  font-size: var(--h1-font-size);
  line-height: var(--heading-line-height)
}

h2 {
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  color: var(--text-color);
  margin-bottom: 8px;
  font-size: var(--h2-font-size);
  line-height: var(--heading-line-height)
}

h3 {
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  color: var(--text-color);
  margin-bottom: 4px;
  font-size: var(--h3-font-size);
  line-height: var(--heading-line-height)
}

h4 {
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  color: var(--text-color);
  font-size: var(--h4-font-size);
  line-height: var(--heading-line-height)
}

h5 {
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  color: var(--text-color);
  font-size: var(--h5-font-size);
  line-height: var(--heading-line-height)
}

h6 {
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  color: var(--text-color);
  font-size: var(--h6-font-size);
  line-height: var(--heading-line-height)
}

li,ul,ol {
  font-family: var(--body-font-family);
  font-weight: var(--body-font-weight);
  line-height: var(--body-text-line-height);
  font-size: var(--body-text-small-font-size)
}

a {
  color: var(--hyperlink-color)
}

a:hover {
  color: var(--hyperlink-hover-color)
}

.rich-text {
  color: var(--text-color)
}

.rich-text h1,.rich-text h1 a {
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  color: var(--text-color);
  font-size: var(--h4-font-size);
  line-height: var(--heading-line-height)
}

.rich-text h2,.rich-text h2 a {
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  color: var(--text-color);
  font-size: var(--h5-font-size);
  line-height: var(--heading-line-height)
}

.rich-text h3,.rich-text h3 a {
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  color: var(--text-color);
  font-size: var(--h6-font-size);
  line-height: var(--heading-line-height)
}

.rich-text h1 a,.rich-text h2 a,.rich-text h3 a {
  color: var(--hyperlink-color)
}

.rich-text h1 a:hover,.rich-text h2 a:hover,.rich-text h3 a:hover {
  color: var(--hyperlink-hover-color)
}

.rich-text p,.rich-text li,.rich-text ul,.rich-text ol,.rich-text a {
  font-family: var(--body-font-family);
  font-weight: var(--body-font-weight);
  line-height: var(--body-text-line-height);
  font-size: var(--body-text-medium-font-size)
}

.rich-text blockquote {
  font-family: var(--body-font-family);
  font-weight: var(--body-font-weight);
  line-height: var(--body-text-line-height);
  font-size: var(--body-text-medium-font-size);
  border-left: 4px solid var(--fill-1-color);
  padding-left: 16px
}

.give {
  margin-bottom: 32px
}

.page {
  padding: var(--page-content-padding, 24px 0);
  max-width: var(--page-max-width);
  min-height: 50vh
}

.page__icon {
  margin-bottom: 24px
}

.page--empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  color: var(--text-color);
  margin-bottom: 4px;
  font-size: var(--h3-font-size);
  line-height: var(--heading-line-height)
}

.page--empty__subtitle {
  margin: 12px 0;
  font-family: var(--body-font-family);
  font-weight: var(--body-font-weight);
  line-height: var(--body-text-line-height);
  font-size: var(--body-text-large-font-size)
}

:root {
  --sermon-details-upper-margin: 0 0 var(--thickness-6, 24px) 0;
  --sermon-details-title-color: var(--text-color);
  --sermon-details-title-font-size: 24px;
  --sermon-details-title-margin: 0 0 var(--thickness-5, 16px) 0;
  --sermon-details-space-between-sections: var(--thickness-6, 24px);
  --sermon-details-meta-separator-content: '•';
  --sermon-details-meta-separator-color: #c7c7c7;
  --sermon-details-meta-separator-margin: 0 2px;
  --sermon-details-meta-margin: 0 0 var(--thickness-5, 16px) 0;
  --sermon-details-tags-margin: 0 0 var(--thickness-5, 16px) 0;
  --sermon-details-tag-color: #3d3d3d;
  --sermon-details-tag-border-radius: 3px;
  --sermon-details-tag-background-color: #f5f5f5f5;
  --sermon-details-tag-padding: var(--thickness-2, 4px) var(--thickness-3, 8px);
  --sermon-details-tag-margin: 0 var(--thickness-3, 8px) 0 0
}

.sermons__title {
  font-size: var(--sermon-details-title-font-size);
  line-height: .75;
  margin: var(--sermon-details-title-margin)
}

.sermons__title a {
  color: var(--sermon-details-title-color)
}

@media (min-width: 768px) {
  .sermons__actions:not(:last-child) {
    margin-right:var(--sermon-details-space-between-sections)
  }
}

@media (max-width: calc(768px - 1px)) {
  .sermons__actions {
    margin-right:0
  }

  .sermons__actions:not(:last-child) {
    margin-bottom: var(--sermon-details-space-between-sections)
  }
}

.sermons__meta-info {
  color: var(--text-color)
}

.sermons__meta {
  font-family: var(--body-font-family);
  font-weight: var(--body-font-weight);
  line-height: var(--body-text-line-height);
  font-size: var(--body-text-small-font-size);
  line-height: 1.3
}

.sermons__meta a {
  color: var(--text-color)
}

.sermons__meta:not(:last-child) {
  margin: var(--sermon-details-meta-margin)
}

.sermons__meta-separator {
  color: var(--sermon-details-meta-separator-color)
}

.sermons__meta-separator::after {
  margin: var(--sermon-details-meta-separator-margin);
  content: var(--sermon-details-meta-separator-content)
}

.sermons__meta-separator:last-child {
  display: none
}

.sermons__tags {
  display: flex;
  flex-wrap: wrap;
  margin: var(--sermon-details-tags-margin);
  row-gap: var(--thickness-3, 8px)
}

.sermons__tag {
  font-family: var(--ui-font-family);
  font-weight: var(--ui-font-weight);
  line-height: var(--ui-line-height);
  font-size: var(--ui-medium-small-size, 14px);
  line-height: var(--body-text-line-height);
  background-color: var(--sermon-details-tag-background-color);
  border-radius: var(--sermon-details-tag-border-radius);
  padding: var(--sermon-details-tag-padding);
  position: relative;
  margin: var(--sermon-details-tag-margin)
}

.sermons__tag,.sermons__tag a {
  color: var(--sermon-details-tag-color)
}

.sermons .fragment-highlight__content .sermons__meta-info {
  font-family: var(--body-font-family);
  font-weight: var(--body-font-weight);
  line-height: var(--body-text-line-height);
  font-size: var(--body-text-small-font-size)
}

.sermons .fragment-highlight__content .fragment-periodical-list-item__subtitle {
  --list-item-subtitle-margin: 8px 0 16px 0
}

.sermons .sermon_details--description {
  margin: var(--sermon-details-meta-margin)
}

:root {
  --sermon-details-description-margin: 0 var(--thickness-6, 24px) var(--thickness-6, 24px) 0;
  --sermon-details-audio-padding: 8px 0 0 0;
  --sermon-details-audio-width: 100%;
  --sermon-details-lower-padding: 0 0 var(--thickness-6, 24px) 0;
  --sermon-details-sidebar-width: 33%;
  --sermon-details-sidebar-title-margin: 0 0 13px 0;
  --sermon-details-sidebar-row-border: 1px solid #dbdbdb;
  --sermon-details-sermon-row-padding: var(--thickness-5, 16px) 0 var(--thickness-5, 16px) 0;
  --sermon-details-sermon-row-thumbnail-height: 48px;
  --sermon-details-sermon-row-thumbnail-width: 85px;
  --sermon-details-sermon-row-info-margin: 0 var(--thickness-5, 16px) 0 var(--thickness-5, 16px);
  --sermon-details-play-button-size: 30px;
  --sermon-details-play-button-color: white;
  --sermon-details-play-button-box-shadow: 0 2px 4px 0 rgba(0,0,0,0.24);
  --sermon-details-play-button-arrow-size: 11px;
  --sermon-details-play-button-arrow-color: #3d3d3d;
  --sermon-details-file-row-padding: 0 var(--thickness-3, 8px) 0 0;
  --sermon-details-file-name-margin: var(--thickness-4, 12px) var(--thickness-5, 16px);
  --sermon-details-file-icon-size: var(--ui-large-size, 16px)
}

:root .light-background {
  --sermon-details-icon-color: var(--icon-background-color)
}

:root .dark-background {
  --sermon-details-icon-color: var(--icon-background-color)
}

.sermon-details__upper {
  margin: var(--sermon-details-upper-margin)
}

.sermon-details__no-cover-icon {
  color: var(--sermon-details-icon-color);
  height: 50px
}

.sermon-details__audio {
  width: var(--sermon-details-audio-width);
  padding: var(--sermon-details-audio-padding)
}

.sermon-details__embed--thumbnail {
  padding-top: 0
}

.sermon-details__embed--thumbnail img {
  object-fit: contain;
  height: auto;
  position: static
}

.sermon-details .sermons__description {
  font-family: var(--body-font-family);
  font-weight: var(--body-font-weight);
  line-height: var(--body-text-line-height);
  font-size: var(--body-text-medium-font-size);
  line-height: 1.5;
  margin: var(--sermon-details-description-margin)
}

.sermon-details__lower {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  color: var(--text-color)
}

@media (max-width: calc(768px - 1px)) {
  .sermon-details__lower {
    flex-direction:column
  }
}

.sermon-details__actions {
  padding: var(--sermon-details-lower-padding)
}

.sermon-details__sidebar {
  flex-shrink: 0
}

.sermon-details__sidebar--files {
  --sermon-details-sidebar-width: 33%
}

.sermon-details__sidebar--series {
  --sermon-details-sidebar-width: 50%
}

@media (min-width: 768px) {
  .sermon-details__sidebar {
    width:var(--sermon-details-sidebar-width)
  }
}

@media (max-width: calc(768px - 1px)) {
  .sermon-details__sidebar {
    width:100%
  }
}

.sermon-details__sidebar-title {
  line-height: 1.13;
  margin: var(--sermon-details-sidebar-title-margin)
}

.sermon-details__sidebar-row {
  border-top: var(--sermon-details-sidebar-row-border)
}

.sermon-details__sidebar-row:last-child {
  border-bottom: var(--sermon-details-sidebar-row-border)
}

.sermon-details .sermon-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--sermon-details-sermon-row-padding)
}

.sermon-details .sermon-row__thumbnail {
  height: var(--sermon-details-sermon-row-thumbnail-height);
  width: var(--sermon-details-sermon-row-thumbnail-width);
  object-fit: contain;
  flex-shrink: 0;
  display: flex
}

.sermon-details .sermon-row__thumbnail svg {
  margin-left: auto;
  margin-right: auto;
  height: 50px;
  width: 42px;
  color: var(--sermon-details-icon-color)
}

.sermon-details .sermon-row__sermon {
  flex-grow: 1;
  margin: var(--sermon-details-sermon-row-info-margin)
}

@media (max-width: calc(768px - 1px)) {
  .sermon-details .sermon-row__sermon .sermon-details__meta-info:not(:first-child) {
    display:none
  }

  .sermon-details .sermon-row__sermon .sermon-details__meta-separator {
    display: none
  }
}

.sermon-details .sermon-row__title {
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  color: var(--text-color);
  font-size: var(--h5-font-size);
  line-height: var(--heading-line-height);
  line-height: 1;
  margin: 0 0 2px 0
}

.sermon-details .sermon-row__title a {
  color: unset
}

.sermon-details .sermon-row__metadata {
  font-family: var(--body-font-family);
  font-weight: var(--body-font-weight);
  line-height: var(--body-text-line-height);
  font-size: var(--body-text-small-font-size)
}

.sermon-details .sermon-row__play-button {
  position: relative;
  width: var(--sermon-details-play-button-size);
  height: var(--sermon-details-play-button-size);
  background-color: var(--sermon-details-play-button-color);
  flex-shrink: 0;
  border-radius: 50%;
  box-shadow: var(--sermon-details-play-button-box-shadow)
}

.sermon-details .sermon-row__play-button svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: var(--sermon-details-play-button-arrow-size);
  height: var(--sermon-details-play-button-arrow-size);
  color: var(--sermon-details-play-button-arrow-color)
}

.sermon-details .file-row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: var(--sermon-details-file-row-padding)
}

.sermon-details .file-row__icon,.sermon-details .file-row__download {
  width: var(--sermon-details-file-icon-size);
  height: var(--sermon-details-file-icon-size);
  color: var(--sermon-details-icon-color);
  flex-shrink: 0
}

.sermon-details .file-row__icon {
  margin: auto
}

.sermon-details .file-row__name {
  font-family: var(--body-font-family);
  font-weight: var(--body-font-weight);
  line-height: var(--body-text-line-height);
  font-size: var(--body-text-small-font-size);
  flex-grow: 1;
  margin: var(--sermon-details-file-name-margin)
}

.fragment-display-selector {
  display: flex;
  justify-content: var(--fragment-display-selector-justify-content, flex-end);
  flex-wrap: nowrap;
  margin-bottom: 16px;
  z-index: 2
}

.fragment-display-selector .fragment-display-selector__button-group {
  display: flex
}

.fragment-display-selector .fragment-display-selector__button-group .fragment-display-selector__button {
  margin: 4px 6px;
  outline: none;
  border: 1px solid transparent
}

.fragment-display-selector .fragment-display-selector__button-group .fragment-display-selector__button:hover {
  border: 1px solid transparent
}

.fragment-display-selector .fragment-display-selector__button-group .fragment-display-selector__button--active {
  color: var(--hyperlink-hover-color);
  border-bottom: 1px solid var(--hyperlink-hover-color);
  border-radius: 0
}

.event-date-square {
  display: var(--event-date-square-display);
  flex-direction: column;
  justify-content: var(--event-date-square-justify-content);
  border: var(--event-date-square-border);
  border-radius: var(--event-date-square-border-radius);
  width: var(--event-date-square-container-diameter);
  height: var(--event-date-square-container-diameter);
  min-width: var(--event-date-square-container-diameter);
  margin: var(--event-date-square-margin);
  background: var(--event-date-square-background)
}

.event-date-square__end {
  border-top: var(--event-date-square-multi-day-divider);
  font-size: var(--event-date-small-font-size);
  font-family: var(--ui-font-family);
  color: var(--event-date-square-text-color);
  padding-top: var(--event-date-square-multi-day-top-padding);
  width: 100%;
  text-align: center
}

.event-date-square__day {
  font-size: var(--event-date-day-font-size);
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  color: var(--event-date-square-text-color);
  line-height: 1;
  width: 100%;
  text-align: center
}

.event-date-square__month {
  font-size: var(--event-date-small-font-size);
  font-family: var(--ui-font-family);
  color: var(--event-date-square-text-color);
  padding: var(--event-date-month-padding);
  background: var(--event-date-month-background);
  border-radius: var(--event-date-square-border-radius) var(--event-date-square-border-radius) 0 0;
  width: 100%;
  text-align: center
}

.event-item__date-inline {
  display: var(--event-date-inline-display);
  margin-right: 4px
}

.event-item__day-of-week {
  margin-right: 4px
}

.event-item__details {
  display: var(--event-details-display)
}

.event-item__highlight-details {
  margin: var(--event-highlight-details-margin);
  max-width: var(--event-highlight-details-max-width);
  text-align: var(--highlight-details-text-align)
}

.event-item__location {
  margin: var(--event-item-location-margin)
}

.event-item__location:after {
  content: var(--event-item-location-date-divider)
}

.events-month-view {
  border: var(--events-month-view-border);
  width: 100%
}

.events-month-view .event-button {
  padding: 12px;
  white-space: nowrap;
  text-align: center
}

.events-month-view a:hover {
  text-decoration: none
}

.hero {
  border-radius: var(--hero-border-radius);
  background-position: center;
  background-size: cover;
  min-height: var(--hero-min-height)
}

.hero__container {
  display: flex;
  flex-direction: var(--hero-flex-direction);
  justify-content: var(--hero-justify-content);
  align-items: var(--hero-align-items);
  width: 100%;
  text-align: var(--hero-text-align, var(--fragment-content-text-align));
  flex-grow: 1
}

.hero__buttons {
  display: flex;
  flex-direction: var(--hero-buttons-flex-direction);
  align-items: var(--hero-buttons-align-items);
  margin: var(--hero-buttons-margin);
  justify-content: var(--hero-buttons-justify-content)
}

@media (min-width: 576px) {
  .hero__buttons {
    flex-direction:row
  }
}

.hero__button {
  display: inline-block;
  margin: 8px 0
}

@media (min-width: 576px) {
  .hero__button {
    margin:16px
  }
}

.hero__header-text,.hero__sub-header-text {
  color: var(--text-color)
}

.hero__sub-header-text {
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  color: var(--text-color);
  font-size: var(--h4-font-size);
  line-height: var(--heading-line-height);
  max-width: var(--hero-sub-header-max-width);
  margin: 0 auto
}

.hero .text-shadow--light.text-shadow--1 {
  text-shadow: 0 0 6px rgba(255,255,255,0.17)
}

.hero .text-shadow--light.text-shadow--2 {
  text-shadow: 0 0 8px rgba(255,255,255,0.61)
}

.hero .text-shadow--light.text-shadow--3 {
  text-shadow: 0 1px 3px #fff
}

.hero .text-shadow--dark.text-shadow--1 {
  text-shadow: 0 0 6px rgba(0,0,0,0.08)
}

.hero .text-shadow--dark.text-shadow--2 {
  text-shadow: 0 0 6px rgba(0,0,0,0.2)
}

.hero .text-shadow--dark.text-shadow--3 {
  text-shadow: 0 1px 3px #000
}

.legacy-fragment-padding .hero__container {
  padding: var(--fragment-padding)
}

.comfortable-fragment-padding .hero__container {
  padding: var(--default-fragment-padding)
}

.html-fragment-iframe {
  border: 0;
  width: 100%
}

.google-map__container {
  height: 475px
}

.google-map label {
  display: inline
}

.mobile-download-cta {
  position: relative
}

.mobile-download-cta__link {
  align-items: center;
  margin-right: 8px
}

.mobile-download-cta__platform-icon {
  margin-right: 4px;
  height: 22px;
  width: 22px;
  margin-bottom: 6px
}

.mobile-download-cta .fragment__content {
  display: flex;
  flex-direction: column
}

.verse-of-the-day__image-container {
  text-align: center
}

.verse-of-the-day__image {
  max-width: 100%;
  border-radius: var(--verse-image-border-radius)
}

.fragment-cta,.cta {
  display: var(--cta-display);
  flex-wrap: var(--cta-flex-wrap);
  align-items: var(--cta-align-items);
  text-align: var(--cta-content-text-align, var(--fragment-content-text-align))
}

.fragment-cta--with-image,.cta--with-image {
  max-height: var(--background-image-max-height)
}

.fragment-cta__background,.cta__background {
  position: var(--cta-background-image-position);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: var(--cta-background-width);
  min-height: var(--cta-min-height);
  background-position: var(--fragment-image-background-position);
  background-size: var(--fragment-image-background-size);
  background-color: var(--fragment-background-color)
}

.fragment-cta__background-image,.cta__background-image {
  border-radius: var(--item-border-radius)
}

.fragment-cta__content,.cta__content {
  display: var(--cta-content-display);
  flex-direction: var(--cta-content-flex-direction);
  justify-content: var(--cta-content-justify-content);
  align-items: var(--cta-content-align-items);
  flex: var(--cta-content-flex);
  position: relative;
  margin: var(--cta-content-margin);
  padding: var(--cta-content-padding);
  max-width: var(--cta-content-max-width);
  text-align: var(--cta-content-text-align, var(--fragment-content-text-align));
  height: var(--cta-content-height)
}

.fragment-cta__title,.cta__title {
  font-size: var(--fragment-title-font-size);
  line-height: var(--fragment-title-line-height);
  letter-spacing: var(--fragment-title-letter-spacing);
  text-transform: var(--fragment-title-text-transform);
  margin-bottom: var(--fragment-title-margin-bottom);
  text-align: var(--fragment-title-text-align);
  color: var(--fragment-title-text-color);
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  color: var(--text-color);
  margin: var(--cta-title-margin)
}

.fragment-cta__subtitle,.cta__subtitle,.fragment-cta__body,.cta__body {
  font-family: var(--body-font-family);
  font-weight: var(--body-font-weight);
  line-height: var(--body-text-line-height);
  font-size: var(--body-text-large-font-size);
  color: var(--text-color);
  margin: var(--cta-body-margin);
  text-align: var(--cta-body-text-align, var(--fragment-content-text-align))
}

.fragment-cta .text-shadow--dark.text-shadow--1 .cta__title,.cta .text-shadow--dark.text-shadow--1 .cta__title,.fragment-cta .text-shadow--dark.text-shadow--1 .cta__body,.cta .text-shadow--dark.text-shadow--1 .cta__body {
  text-shadow: 0 0 6px rgba(0,0,0,0.08)
}

.fragment-cta .text-shadow--dark.text-shadow--2 .cta__title,.cta .text-shadow--dark.text-shadow--2 .cta__title,.fragment-cta .text-shadow--dark.text-shadow--2 .cta__body,.cta .text-shadow--dark.text-shadow--2 .cta__body {
  text-shadow: 0 0 6px rgba(0,0,0,0.2)
}

.fragment-cta .text-shadow--dark.text-shadow--3 .cta__title,.cta .text-shadow--dark.text-shadow--3 .cta__title,.fragment-cta .text-shadow--dark.text-shadow--3 .cta__body,.cta .text-shadow--dark.text-shadow--3 .cta__body {
  text-shadow: 0 1px 3px #000
}

.fragment-cta .text-shadow--light.text-shadow--1 .cta__title,.cta .text-shadow--light.text-shadow--1 .cta__title,.fragment-cta .text-shadow--light.text-shadow--1 .cta__body,.cta .text-shadow--light.text-shadow--1 .cta__body {
  text-shadow: 0 0 6px rgba(255,255,255,0.17)
}

.fragment-cta .text-shadow--light.text-shadow--2 .cta__title,.cta .text-shadow--light.text-shadow--2 .cta__title,.fragment-cta .text-shadow--light.text-shadow--2 .cta__body,.cta .text-shadow--light.text-shadow--2 .cta__body {
  text-shadow: 0 0 8px rgba(255,255,255,0.61)
}

.fragment-cta .text-shadow--light.text-shadow--3 .cta__title,.cta .text-shadow--light.text-shadow--3 .cta__title,.fragment-cta .text-shadow--light.text-shadow--3 .cta__body,.cta .text-shadow--light.text-shadow--3 .cta__body {
  text-shadow: 0 1px 3px #fff
}

.fragment-cta .button,.cta .button {
  white-space: pre-wrap
}

.legacy-fragment-padding .fragment-cta,.legacy-fragment-padding .cta {
  padding: var(--fragment-padding)
}

.legacy-fragment-padding .fragment-cta--with-image,.legacy-fragment-padding .cta--with-image {
  padding: var(--fragment-padding)
}

.default-fragment-padding .fragment-cta,.default-fragment-padding .cta {
  padding: var(--default-fragment-padding)
}

.default-fragment-padding .fragment-cta--with-image,.default-fragment-padding .cta--with-image {
  padding: var(--default-fragment-padding)
}

.contact-info-container {
  display: flex;
  flex-direction: var(--contact-info-container-flex-direction);
  justify-content: var(--contact-info-container-justify-content);
  align-items: var(--contact-info-container-align-items)
}

.contact-info-item {
  display: flex;
  flex-direction: var(--contact-info-item-flex-direction);
  justify-content: var(--contact-info-item-justify-content);
  align-items: var(--contact-info-item-align-items);
  border: var(--contact-info-item-border);
  border-width: var(--contact-info-item-border-width);
  border-radius: var(--contact-info-item-border-radius);
  margin: var(--contact-info-item-margin);
  padding: var(--contact-info-item-padding);
  max-width: 100%;
  width: var(--contact-info-item-width)
}

.contact-info-item:last-child {
  --contact-info-item-margin: 0
}

.contact-info-item a {
  color: var(--text-color)
}

.contact-info-item__details {
  margin: var(--contact-info-item-details-margin);
  text-align: var(--contact-info-item-details-text-align, var(--fragment-content-text-align));
  color: var(--text-color);
  overflow-wrap: break-word;
  max-width: 100%;
  min-width: 0
}

.custom-form-content-container {
  display: flex;
  justify-content: center;
  color: var(--text-color)
}

.sermon-grid-item__media-container {
  margin: var(--sermon-grid-item-media-container-margin);
  border-radius: var(--sermon-grid-item-media-container-border-radius);
  overflow: hidden
}

.sermon-grid-item .multimedia-embed-wrapper--image {
  padding-top: 0;
  background: none
}

.sermon-grid-item .multimedia-embed-wrapper--image img {
  object-fit: contain;
  height: auto;
  max-height: 500px;
  width: 100%;
  position: static
}

.sermon-grid-item .multimedia-embed-wrapper--placeholder {
  background-color: var(--image-placeholder-color)
}

.sermon-grid-item__icon {
  display: block;
  width: 64px;
  color: var(--icon-color)
}

.sermon-grid-item__details {
  margin: var(--sermon-grid-item-details-margin)
}

.sermon-grid-item__date {
  font-family: var(--body-font-family);
  font-weight: var(--body-font-weight);
  line-height: var(--body-text-line-height);
  font-size: var(--body-text-medium-font-size);
  color: var(--text-color);
  margin: 0
}

.sermon-grid-item__title {
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  font-size: var(--h4-font-size);
  line-height: var(--heading-line-height);
  color: var(--text-color);
  display: inline-block;
  margin: var(--sermon-grid-item-title-margin)
}

.sermon-grid-item__title:hover {
  color: var(--hyperlink-hover-color)
}

.sermon-grid-item__passages {
  margin: 0
}

.sermon-grid-item__passages a {
  color: var(--text-color)
}

.sermon-item__passage-dot {
  margin: 0 4px
}

.sermon-highlight__passage {
  font-family: var(--body-font-family);
  font-weight: var(--body-font-weight);
  line-height: var(--body-text-line-height);
  font-size: var(--body-text-large-font-size);
  color: var(--text-color)
}

.sermon-highlight__passage a {
  color: var(--text-color)
}

.sermons-fragment {
  --sermon-details-tags-margin: 0
}

.sermons-fragment .sermons__title {
  line-height: var(--heading-line-height)
}

.sermons-fragment .fragment-periodical-list-item__container .fragment-item-icon-container:hover {
  color: var(--icon-color)
}

.sermons-fragment .fragment-highlight {
  --sermon-details-tags-margin: 0 0 24px 0
}

.sermons-fragment .fragment-highlight .sermons__title {
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  color: var(--text-color);
  margin-bottom: 8px;
  font-size: var(--h2-font-size);
  line-height: var(--heading-line-height);
  margin-bottom: 16px
}

.service-times-container {
  display: flex;
  flex-wrap: wrap;
  margin: calc(-1 * var(--service-times-item-gap) / 2);
  flex-direction: var(--service-times-flex-direction);
  justify-content: var(--service-times-justify-content)
}

.clock-icon {
  color: var(--icon-color);
  display: var(--service-times-icon-display);
  margin-right: 8px
}

.clock-icon svg {
  margin-bottom: 5px
}

.service-times-item-wrapper {
  padding: calc(var(--service-times-item-gap) / 2);
  width: var(--service-times-item-width);
  justify-content: end
}

.service-times-item {
  display: flex;
  flex-direction: var(--service-times-item-flex-direction);
  align-items: var(--service-times-item-align-items);
  background: var(--service-times-item-background);
  padding: var(--service-times-item-padding);
  border: var(--service-times-item-border);
  border-radius: var(--service-times-item-border-radius);
  box-shadow: var(--service-times-item-box-shadow);
  text-align: var(--service-times-item-text-align, var(--fragment-content-text-align));
  justify-content: var(--service-times-item-justify-content);
  height: 100%
}

.service-times-item .fragment-item-icon-container {
  font-family: var(--body-font-family);
  font-weight: var(--body-font-weight);
  line-height: var(--body-text-line-height);
  font-size: var(--body-text-small-font-size)
}

.service-times-item__inline-day {
  display: var(--service-times-inline-day-display)
}

.service-times-item__service-container {
  margin: var(--service-time-container-margin);
  color: var(--text-color);
  justify-content: var(--service-time-container-justify-content);
  display: var(--service-time-container-display);
  flex-direction: var(--service-time-container-flex-direction);
  align-items: var(--service-time-container-align-items);
  text-align: var(--service-time-container-text-align, var(--fragment-content-text-align))
}

.service-times-item__title {
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  color: var(--text-color);
  font-size: var(--h4-font-size);
  line-height: var(--heading-line-height);
  overflow-wrap: break-word
}

.service-times-item__time-container {
  margin: var(--service-times-item-time-top-margin) 0 var(--service-times-item-time-bottom-margin);
  font-family: var(--body-font-family);
  font-weight: var(--body-font-weight);
  line-height: var(--body-text-line-height);
  font-size: var(--body-text-large-font-size);
  display: inline-flex
}

.service-times-item__time-container:last-child {
  margin: var(--service-times-item-time-top-margin) 0 0 0
}

.blog-preview-text {
  word-break: break-word;
  color: var(--text-color)
}

.blog-preview-text h1,.blog-preview-text h2,.blog-preview-text h3,.blog-preview-text p {
  margin: 0;
  padding: 0;
  font-size: inherit;
  line-height: inherit
}

.blog-preview-text br,.blog-preview-text ul,.blog-preview-text ol,.blog-preview-text img {
  display: none
}

.blog-item-details {
  margin: var(--blog-item-details-margin);
  display: var(--blog-item-details-display);
  justify-content: var(--blog-item-details-justify-content);
  align-items: var(--blog-item-details-align-items);
  text-align: var(--blog-item-details-text-align, var(--fragment-content-text-align));
  flex-direction: var(--blog-item-details-flex-direction)
}

.blog-item-details__title {
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  font-size: var(--h4-font-size);
  line-height: var(--heading-line-height);
  color: var(--text-color);
  margin: var(--blog-item-details-title-margin);
  text-align: var(--blog-item-details-title-text-align)
}

.blog-item-details__title:hover {
  color: var(--hyperlink-hover-color)
}

.blog-item-details__preview {
  margin: var(--blog-grid-item-preview-margin);
  text-align: var(--blog-grid-item-preview-text-align)
}

.blog-item-details__post-info {
  font-family: var(--body-font-family);
  font-weight: var(--body-font-weight);
  line-height: var(--body-text-line-height);
  font-size: var(--body-text-medium-font-size);
  color: var(--text-color);
  margin: var(--blog-item-details-post-info-margin);
  text-align: var(--blog-item-details-post-info-text-align)
}

.blog-item-details__page-link {
  text-align: var(--blog-item-details-page-link-text-align);
  margin: var(--blog-item-details-page-link-margin)
}

.blog-item-image {
  border-radius: var(--blog-item-image-border-radius);
  background-size: cover;
  background-position: 50%;
  padding-top: var(--multimedia-aspect-ratio-percentage);
  height: var(--blog-item-image-height);
  max-height: var(--blog-item-image-max-height)
}

.blog-item-no-image {
  border-radius: var(--blog-item-image-border-radius);
  background-size: cover;
  background-position: 50%;
  padding-top: var(--multimedia-aspect-ratio-percentage);
  height: var(--blog-item-image-height);
  max-height: var(--blog-item-image-max-height);
  background: var(--fill-1-color)
}

.blog-grid-item {
  box-shadow: var(--item-box-shadow);
  overflow: hidden
}

.blog-list-item {
  display: var(--blog-list-item-display);
  border: var(--list-item-border);
  padding: var(--list-item-padding);
  margin: var(--list-item-margin);
  border-width: var(--list-item-border-width);
  border-radius: var(--list-item-border-radius);
  box-shadow: var(--item-box-shadow);
  background: var(--list-item-background);
  overflow: hidden
}

.blog-list-item:first-child {
  padding-top: var(--top-list-item-top-padding)
}

.blog-list-item__image-container {
  width: var(--blog-list-image-width);
  flex: 0 0 auto
}

.blog-highlight {
  display: var(--blog-highlight-display);
  grid-template-columns: var(--blog-highlight-grid-template-columns);
  grid-column-gap: var(--blog-highlight-grid-column-gap);
  overflow: hidden
}

.blog-highlight__icon {
  margin: 0 auto
}

.blog-highlight__image-container {
  width: var(--highlight-image-container-width);
  margin: var(--highlight-image-container-margin);
  flex: 0 0 auto
}

.blog-highlight__post-info {
  font-family: var(--body-font-family);
  font-weight: var(--body-font-weight);
  line-height: var(--body-text-line-height);
  font-size: var(--body-text-medium-font-size);
  color: var(--text-color);
  margin: var(--blog-highlight-post-info-margin, 0)
}

.blog-highlight__preview {
  margin: var(--blog-highlight-preview-margin)
}

.blog-highlight__detail-page-link {
  display: inline-block;
  width: -webkit-fit-content;
  width: fit-content;
  cursor: pointer;
  margin: var(--blog-highlight-detail-page-link-margin)
}

.blog-highlight--no-image .blog-highlight__image-container {
  width: var(--blog-highlight-background-width);
  height: var(--blog-highlight-background-height);
  background: var(--blog-item-image-container-background);
  border-radius: var(--blog-item-image-border-radius)
}

.live-stream__embed {
  border-radius: var(--item-border-radius);
  overflow: hidden
}

.livestream-link {
  padding: 12px
}

.livestream-link a {
  text-decoration: underline
}

.digital-signage__embed {
  border-radius: var(--item-border-radius);
  --multimedia-aspect-ratio-percentage: 56.25%;
  overflow: hidden
}

.detail-page__container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 16px
}

@media (min-width: 768px) {
  .detail-page__container {
    padding:0 64px
  }
}

.event-details .event-date-square__end {
  font-family: var(--ui-font-family);
  font-weight: var(--ui-font-weight);
  line-height: var(--ui-line-height);
  font-size: var(--ui-medium-size)
}

.event-details__title {
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  color: var(--text-color);
  margin-bottom: 8px;
  font-size: var(--h2-font-size);
  line-height: var(--heading-line-height);
  margin-bottom: 12px
}

.event-details__details {
  font-family: var(--body-font-family);
  font-weight: var(--body-font-weight);
  line-height: var(--body-text-line-height);
  font-size: var(--body-text-medium-font-size)
}

.event-details__details-header {
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  color: var(--text-color);
  font-size: var(--h5-font-size);
  line-height: var(--heading-line-height);
  margin-bottom: 16px
}

.event-details__info {
  font-family: var(--body-font-family);
  font-weight: var(--body-font-weight);
  line-height: var(--body-text-line-height);
  font-size: var(--body-text-large-font-size)
}

.footer {
  background-color: var(--background-2-color);
  padding: 32px 0;
  color: var(--text-color)
}

.footer .block-new-section {
  color: var(--text-color);
  border-color: var(--text-color)
}

.footer .container {
  max-width: var(--content-container-max-width)
}

.footer__social {
  text-align: center
}

.footer__social-link {
  margin: 0 8px
}

.footer__social-link:hover {
  text-decoration: none
}

.footer__social-link .social-icon--faithlife {
  color: inherit
}

.footer__sections {
  display: grid;
  margin: 32px 0;
  grid-row-gap: 32px
}

@media (min-width: 768px) {
  .footer__sections {
    grid-template-columns:repeat(2, 45%);
    grid-column-gap: 32px;
    margin: 64px 0
  }
}

@media (min-width: 992px) {
  .footer__sections {
    grid-template-columns:repeat(4, 22%)
  }
}

.footer__menu {
  margin: 0 0 32px;
  text-align: center
}

.footer__menu-link {
  display: inline-block;
  margin: 12px
}

.footer__copyright {
  text-align: center
}

.members-page {
  margin: 32px 0;
  text-align: center
}

@media (min-width: 768px) {
  .members-page {
    margin:32px 0
  }
}

.homepage {
  padding: var(--homepage-padding)
}

.page-header {
  padding-bottom: var(--page-header-padding-bottom, 8px);
  font-size: var(--h2-font-size);
  text-align: var(--page-header-text-alignment, center);
  position: relative;
  z-index: 11
}

.header-engagement {
  display: flex;
  flex-shrink: 0
}

.header-engagement__text {
  font-size: var(--body-text-small-font-size);
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  vertical-align: middle;
  color: var(--menu-item-color);
  text-transform: var(--menu-item-text-transform)
}

.header-engagement__text:hover {
  color: var(--menu-item-hover-color)
}

.header-engagement__profile {
  border: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  color: var(--hyperlink-color)
}

.header-engagement__link-icon {
  display: inline-block;
  margin-right: 8px;
  width: 24px;
  height: 24px
}

.header-engagement__link-icon--user {
  border-radius: var(--header-icon-border-radius)
}

.header-engagement__link-icon--user-default-icon {
  color: var(--header-default-icon-color)
}

.header-engagement__follow {
  margin-left: 4px
}

.header-engagement__link {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  flex-shrink: 0;
  padding: 0 12px
}

.header-engagement__link:last-child {
  padding-right: 0
}

.header-engagement__link:hover {
  text-decoration: none
}

@media (min-width: 992px) {
  .header-engagement__link {
    margin-bottom:0
  }
}

.profile-dropdown {
  position: relative
}

.profile-dropdown__container {
  width: 180px;
  box-shadow: var(--header-dropdown-box-shadow);
  text-transform: var(--header-dropdown-text-transform);
  border-radius: var(--header-dropdown-border-radius);
  border: var(--header-dropdown-border)
}

.profile-dropdown__list {
  margin: 0;
  padding: 0
}

.profile-dropdown__list-item {
  display: block;
  padding: 12px
}

.profile-dropdown__item:hover {
  text-decoration: none
}

.group-branding {
  position: relative;
  margin: 0 8px 0 0;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-wrap: wrap
}

.group-branding__name {
  text-transform: var(--menu-item-text-transform);
  font-size: var(--group-branding-font-size);
  line-height: var(--group-branding-line-height);
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  color: var(--menu-item-color)
}

.group-branding__name,.group-branding__name:hover {
  text-decoration: none
}

.group-branding__name:nth-of-type(2) {
  margin-left: 16px
}

.group-branding__icon {
  border-radius: var(--header-icon-border-radius)
}

.group-branding__icon--small {
  height: 32px
}

.group-branding__icon--medium {
  height: 44px
}

.group-branding__icon--large {
  height: 96px
}

.group-branding .edit-item {
  min-width: unset
}

.slideout-menu-account {
  display: flex;
  padding: 24px 24px 12px;
  font-family: var(--menu-item-font-family);
  font-weight: var(--menu-item-font-weight);
  line-height: var(--body-text-line-height);
  color: var(--menu-item-color)
}

.slideout-menu-account--unauthenticated {
  padding-bottom: 32px
}

.slideout-menu-account__unauthenticated-link {
  display: flex;
  align-items: center;
  margin-right: 24px;
  font-size: var(--body-text-small-font-size);
  color: var(--menu-item-color);
  text-transform: var(--menu-item-text-transform);
  align-self: flex-start;
  white-space: nowrap
}

.slideout-menu-account__unauthenticated-link:hover {
  color: var(--menu-item-hover-color)
}

.slideout-menu-account__unauthenticated-icon {
  margin-right: 4px;
  width: 24px;
  height: 24px
}

.slideout-menu-account__profile-image {
  border-radius: 5px;
  margin-right: 16px;
  width: 44px;
  height: 44px
}

.slideout-menu-account__sign-out-link {
  font-size: var(--body-text-small-font-size);
  color: var(--menu-item-color)
}

.slideout-menu-account__sign-out-link:hover {
  color: var(--menu-item-hover-color)
}

.slideout-menu-account__name {
  margin-bottom: 4px;
  font-size: var(--body-text-large-font-size)
}

:root {
  --people-fragment-content-top: calc(-1 * 32px);
  --people-fragment-header-align-items: baseline;
  --people-fragment-header-container-position: relative;
  --people-fragment-header-container-top: -40px;
  --people-fragment-header-display: flex;
  --people-fragment-header-flex-direction: row;
  --people-fragment-header-margin: 0 0 24px 0;
  --people-fragment-person-label-align-self: center;
  --people-label-title-margin: 0 8px 0 auto;
  --people-grid-grid-gap: 24px;
  --people-grid-grid-template-columns: repeat(2, 1fr);
  --people-grid-profile-image-size: 175px;
  --people-list-header-background-color: rgba(0,0,0,0.07);
  --people-list-header-justify-content: flex-start;
  --people-list-header-align-items: center;
  --people-list-header-font-weight: 600;
  --people-list-display: flex;
  --people-list-padding: 10px 4px;
  --people-list-profile-image-size: 175px;
  --people-list-display: grid;
  --people-list-item-padding: 8px 4px;
  --people-list-item-border-bottom: 1px solid rgba(0,0,0,0.07);
  --people-list-item-height: 55px;
  --people-list-profile-image-size: 50px;
  --people-list-profile-image-border-radius: 5px;
  --people-pagination-margin: 16px 0 0;
  --people-grid-profile-image-size-md: 90px;
  --people-fragment-header-flex-direction-md: row;
  --people-grid-grid-gap-xs: 8px;
  --people-fragment-header-margin-xs: 0 0 12px 0;
  --people-grid-grid-template-columns-xs: 1fr;
  --people-fragment-header-flex-direction-xs: column;
  --people-fragment-person-label-margin-left-xs: unset;
  --people-fragment-person-label-margin-top-xs: 8px;
  --people-fragment-person-label-margin-bottom-xs: 8px;
  --people-fragment-header-align-items-xs: stretch;
  --people-label-title-margin-xs: 8px 0
}

.fl-image{
  display: block;
  margin-bottom: 12px;
  margin-top: 12px;
  max-width: 100%;
}

.ql-align-center{
  text-align: center;
}

.ql-align-center>img{
  margin: auto;
}

:root .hero__container{
  position: relative !important;
}

.hero__container .display:before{
  content: '' !important;
}

.hero__text-container{
  width: 100%;
}

.light-background.fragment{
  background-color: transparent;
}
